.App {
  /* text-align: center; */
  padding: 2px;
  overflow: hidden;
  font-family: "Saol Display", "GT America Extended", system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

@font-face {
  font-family: "Testimonia";
  src: local("Testimonia"), url(../fonts/Testimonia.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "Wkwk";
  src: local("Wkwk"), url(../fonts/Wkwk.otf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "Evander";
  src: local("Evander"), url(../fonts/Evander-ExtraLight.otf) format("opentype");
  src: local("Evander"), url(../fonts/Evander-ExtraBold.otf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

input[type="radio"] {
  margin-top: -1px;
  vertical-align: middle;
}

/* html {
  background-image: url("../images/stbedeold.jpeg");
} */

.font-headline {
  font-family: "Saol Display, Georgia, Cambria, Times New Roman, Times, serif" !important;
}

.font-headline-2 {
  font-family: Saol Text, Georgia, Cambria, Times New Roman, Times, serif;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
  /* position: absolute; */
  /* width: 20px !important; */
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  /* display: flex; */
  /* flex-direction: column;
  align-items: right;
  justify-content: right; */
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

#light-of-the-world {
  z-index: 1000;
  position: relative;
}

.footerlogo {
  width: 200px;
}

.footer-contact-block {
  padding: 0px 10px;
  border-right: 1px solid #fff;
}

.footer-contact-block:last-child {
  border: none;
}

.footer-copyright-block {
  padding: 0px 10px;
  font-size: small;
  border-right: 1px solid #fff;
}

.footer-copyright-block:last-child {
  border: none;
}

#footer-logo {
  width: 25%;
}

#footer-bottom #anglican-logo {
  width: 25%;
}

#footer-bottom #diocese-logo {
  width: 25%;
}

nav li a {
  font-size: 14px;
  padding: 8px 15px;
  color: gray !important ;
}

.wrapper {
  width: 100vw;
  background-color: #932207;
}

.wrapper #anglican-diocese {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100vw;
  /* background-color: purple; */
}

.helper::after {
  color: blue;
}

@media (max-width: 768px) {
  .helper::after {
    color: red;
    content: "*";
  }

  .coverimg {
    height: 100vh !important;
    width: auto !important;
  }

  nav ul {
    height: 30vh;
    justify-content: space-evenly;
  }

  nav li a {
    color: black !important;
  }

  #backgroundvideo {
    height: 120vh !important;
    width: 100vw !important;
    /* overflow: hidden !important; */
    overflow-clip-margin: content-box;
  }
  .sectionWrapper {
    height: 90vh !important;
  }

  .navigationDesktop-navbar {
    display: flex;
    flex-direction: column !important;
    /* background-color: red; */
    height: auto;
  }

  .prayer-text {
    font-size: 3vh !important;
  }

  .card-container {
    flex-direction: column;
  }

  .card-container > div {
    width: auto !important;
  }

  .card-body {
    padding: 0px !important;
  }

  .card {
    padding: 0px !important;
  }

  .item-container {
    flex-direction: column !important;
    width: 100% !important;
  }

  .item-info {
    text-align: center;
    width: 100% !important;
  }

  .item-holder {
    width: 100vw !important;
    height: 80vh !important;
    flex-direction: column !important;
    justify-content: space-around;
    margin-right: 5px !important;
  }

  .item-top-div {
    width: 100% !important;
    /* height: 20% !important; */
  }

  .item-img {
    align-items: center !important;
    transform: scale(0.8);
    width: auto !important;
  }

  .accordion-body > div {
    flex-direction: column;
  }
  .accordion-body > div > img {
    width: 100% !important;
    margin: 10px auto 20px auto;
  }

  .wrapper #anglican-diocese,
  #copyright {
    display: flex;
    flex-direction: column;
    height: 150px;
  }

  .wrapper #anglican-diocese > a > img {
    width: 200px;
  }

  #disclaimer {
    width: 90% !important;
    left: 5% !important;
    max-height: 100%;
  }

  #disclaimer-body {
    max-height: 450px;
    overflow: scroll;
  }

  #ops-items-holder {
    width: 100vw !important;
    left: 0 !important;
    top: 0 !important;
    padding: 10px !important;
  }

  #item-button-holder > div {
    width: 50vw !important;
  }

  .cardimgovl {
    display: none;
  }

  .cardimgovl + div {
    width: 100% !important;
  }

  #history-img-container {
    display: none;
  }

  #history-card-index > div > div {
    width: auto !important;
  }

  .prayer-holder {
    margin-bottom: 40px;
    min-height: 100vh !important;
    max-height: 100vh !important;
    overflow: scroll;
  }

  .prayer-holder > div {
    height: 90vh !important;
  }

  .prayer-holder > div > div {
    height: 90vh !important;
    overflow: scroll;
  }

  .prayer-holder > div > div {
    height: 90vh !important;
    align-items: center;
    display: flex;
  }

  /* #carousel-praise {
    min-height: 90vh !important;
    height: 90vh !important;
  } */

  .praise-text {
    font: 600 42px/42px "open sans" !important;
  }

  .upcoming {
    flex-direction: column !important;
  }

  .upcoming > div {
    width: 100% !important;
  }

  .bible-verse-hero {
    /* margin-top: 110vh !important; */
    display: flex;
  }

  .bible-verse-hero > img {
    width: 90% !important;
  }

  .h3.cursive.header {
    font-size: 2rem !important;
  }

  .world {
    font-size: 100px !important;
  }
  .title > h1 {
    font-size: 70px;
    color: white;
  }
  .nblockquote {
    font-size: 50px !important;
  }

  .about-container .d-flex {
    flex-direction: column !important;
  }

  .about-container .give-images {
    width: 70% !important;
  }

  .footer-copyright-block {
    border-right: 0px;
  }

  .outer {
    flex-direction: column;
    /* max-height: 100vh; */
    margin-bottom: 20px;
  }

  .our-journey-video {
    display: flex;
    flex-direction: column;
  }

  .our-journey-video > iframe {
    width: 100%;
    height: 200px !important;
  }

  /* About page */
  #our-incumbent > div {
    flex-direction: column !important;
    width: 100% !important;
  }

  #our-incumbent > div > div > img {
    width: 100% !important;
    /* padding: 10%; */
  }

  #our-incumbent > div > * {
    width: 90vw !important;
    padding: 10%;
  }

  #our-priests-list > div > img {
    width: 100% !important;
    height: auto !important;
  }

  #about-priest {
    width: 100% !important;
  }
  #change-view {
    display: none !important;
  }
}

.item-info > p {
  margin-bottom: 20px;
  width: 90%;
  overflow-x: hidden;
  overflow-y: scroll;
}

#banner .wrapper {
  width: calc(96% - 100px);
  /*top: 75%;*/
}

nav {
  margin: 10px 0px !important;
}

.alignleft {
  width: 50%;
  height: auto;
}

.view-100 {
  width: 100vw !important;
  height: 100vh !important;
  min-width: 100vw !important;
  min-height: 100vh !important;
  margin: auto;
  align-items: center;
}

.view-90 {
  width: 90vw !important;
  height: 90vh !important;
  min-width: 90vw !important;
  min-height: 90vh !important;
  margin: auto;
  align-items: center;
}

.view-80 {
  width: 80vw !important;
  height: 80vh !important;
  min-width: 80vw !important;
  min-height: 80vh !important;
  margin: auto;
  align-items: center;
}

.alignright {
  width: 50%;
  height: auto;
}

.video-container {
  position: relative;
  left: 0;
  top: 0;
  opacity: 0.7;
  width: 100%;
  height: -webkit-calc(100vh - 380px);
  height: -moz-calc(100vh - 380px);
  height: calc(100vh - 55px);
}

.login-container {
  margin: auto;
  /* width: 70%; */
  width: -webkit-calc(100% - 100px);
  width: -moz-calc(100% - 100px);
  width: calc(100% - 100px);
  align-items: center;
  box-shadow: 1px 1px 10px 1px gray;
  display: grid;
  margin: auto;
  background-color: rgb(211 167 226 / 36%);
}

.login-container-header {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.login-container-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  font-size: small;
}

.loginscreen {
  padding: 5%;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}

.socialmedialogin {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50%;
}
.socialmedialogin > img {
  width: 50px;
  box-shadow: 1px 1px 50px 1px gray;
  border-radius: 25%;
}
.loginform {
  border-left: 1px solid red;
  width: 50%;
  height: 100%;
  margin-left: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loginform > form {
  margin-left: 40px;
  text-align: left;
  height: 100%;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
}

.head {
  font-size: xx-large;
  font-weight: 900;
}

.stbcard-holder {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  overflow-x: scroll;

  /* gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin: 0 0 1.5rem;
   */
  height: 50vh;
  width: 90vw;
  margin: auto;
  transition: all 1s;
}

.stbcard {
  padding: 1.5rem;
  border-radius: 16px;
  /* background: linear-gradient(85deg, #434343, #262626); */
  /* color: #fff; */
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  width: 30vw;
  margin: 0;
}

.ssstbcard:hover {
  position: absolute;
  /* width: 100%; */
  /* transform: scale(1.2); */
  width: 50%;
  z-index: 100;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
}

.card-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
  align-items: flex-start;
  margin-top: 20px;
}

.card-container-90 {
  width: 90%;
  margin: auto;
  align-items: center;
  margin-top: 20px;
}

.card-container > div {
  width: 25%;
  /* height: 50vh; */
  margin: 5px;

  overflow: hidden;
  /* transition: height 5s ease; */
  border-radius: 0px;
  border: none;
}

/* .card-container > div:hover {
  box-shadow: 0px 0px 10px 0px gray;
} */

/* .card-container > div:hover {
  height: auto;
} */
/* .card-container > div:hover {
  transform: scale(2.1);
  transition-duration: 13s ease-in-out;
} */

.card-container > div > div > img {
  height: 100%;
  /* width: 50%; */
  /* top: 0px;
  left: 25%; */
  /* position: absolute; */
  object-fit: cover;
  /* border-radius: 50%; */
}

.card-container > div > div > div > svg {
  color: red;
  position: absolute;
  right: 20px;
  bottom: 10px;
  cursor: pointer;
}

.card-container > div > div > p {
  text-align: left;
  line-height: 1.4;
  /* height: 20%; */
  text-overflow: ellipsis;
  /* overflow: hidden; */
  transition: transform 0.3s ease-out;
  /* transform: scaleY(0); */
}

.card-container > div > div > p.expand {
  transform: scaleY(1);
}

.cardimgovl {
  width: 25%;
  /* height: 50px; */
  margin: 10px;
  object-fit: cover;
  /* box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3); */
  /* filter: blur(8px);
  -webkit-filter: blur(2px); */
}

.item-container {
  /* flex-direction: column !important; */
  width: 100% !important;
}

.item-holder {
  border: 1px solid rgba(128, 128, 128, 0.414);
  margin: 5px;
  width: 40vw;
  height: 20vw;
  text-align: left;
  margin-right: 30px;
  position: relative;

  /* -webkit-transition: box-shadow 1s ease;
  -moz-transition: box-shadow 1s ease;
  -o-transition: box-shadow 1s ease;
  transition: box-shadow 1s ease; */

  /* transition: left 1s ease; */

  /* box-shadow: 0 0 5px rgba(128, 128, 128, 0.414); */

  background-color: #fff;
  -webkit-box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.5);
  box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.5);
  border-radius: 10px;
  overflow: hidden;
}
/*
.item-holder:hover {
   box-shadow: 0px 0px 0px 30px rgba(128, 128, 128, 0.414);
  left: 1px;
  transform: rotate(2deg) translateY(-5px) scale(1.025);
  transform: translateY(-5px) scale(1.025);
  background: green; 
  padding: 2px;
}*/

.item-info {
  width: 100%;
  margin: 10px;
}

.item-img {
  width: 200px;
  /* height: 50%; */
}

.item-img-overlay {
  position: absolute;
  opacity: 0.2;
  -webkit-opacity: 20%;
  -moz-opacity: 0.2;
  -khtml-opacity: 0.2;
  z-index: 0;
  left: 50%;
  top: 10%;
  -webkit-transform: scale(45deg);
  -webkit-transform: rotate(30deg);
  transform: scale(45deg);
  transform: rotate(30deg);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.openItem {
  -webkit-user-select: auto;
  -khtml-user-select: auto;
  -moz-user-select: auto;
  -o-user-select: auto;
  user-select: auto;
}

/* .closedItem {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  background-color: rgba(128, 128, 128, 0.469) !important;

  background: repeating-linear-gradient(
    135deg,
    #000000 0,
    #000000 5%,
    transparent 0,
    transparent 50%
  );
  background-size: 3em 3em;
  background-color: #ffffff;
  opacity: 0.2;
}

.upcomingItem {
  background: linear-gradient(
    90deg,
    transparent 45%,
    #000000 4%,
    #000000 5%,
    transparent 50%,
    transparent 10%,
    #000000 2%,
    #000000 3%,
    transparent 1%
  );
  background-size: 1em 1em;
  background-color: #ffffff;
  opacity: 0.5;
 
} */

.upcomingItem > div:nth-child(2) > div > span::after {
  content: "Coming Soon";
  /* background: lightgreen; */
}

.closedItem > div > div > div > img,
.upcomingItem > div > div > div > img {
  opacity: 0.7;
}

.item-top-div {
  width: 50%;
}

#current-bid {
  font-size: xx-large;
}

.member-info {
  border: 1px solid lightgray;
  margin: 10px;
  width: 30%;
  height: 200px;
}
.single-member-info {
  width: 30vw;
  /* transform: scale(2); */
}
.member-img {
  width: 90%;
  height: 100px;
  margin: 5px;
}

.member-details {
  width: 100% !important;
  text-align: left;
}

#ids {
  display: flex;
  flex-direction: row;

  width: 90%;
  height: 15px;
  font-size: small;
  text-align: center;
  right: 10px;
  justify-content: space-between;
}

#ids > #member-id {
  background-color: brown;
  color: white;
}
#ids > #family-id {
  background-color: cadetblue;
  color: white;
}

.lom-ol {
  text-align: left;
  margin-left: 50px;
  max-height: 90vh;
  line-height: 1.5;
  border: 1px solid lightgray;
  padding: 10px;
  overflow: scroll;
}

.lom-li {
  border-bottom: 1px solid gray;
  cursor: pointer;
}

.lom-li:hover {
  background-color: lightgray;
}

#CoverScreen {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  background-color: #d3d3d31c;
  display: flex;
  justify-content: center;
  align-items: center;
}

#disclaimer {
  position: sticky;
  width: 90%;
  height: 100vh;
  padding: 30px;
  /* margin: auto; */
  top: 5%;
  left: 5%;
  z-index: 99;
  background-color: whitesmoke;
  box-shadow: 1px 1px 10px 1px gray;
  text-align: left;
  line-height: 1.5;
  z-index: 100;
  overflow: scroll;
}

#disclaimer-body {
  height: 85%;
  overflow: scroll;
}

#silent-auction {
  width: 80%;
  margin: auto;
  text-align: justify;
  margin-bottom: 30px;
  line-height: 1.2;
}

.cover-screen {
  background-color: #808080;
  position: sticky;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100svh;
  z-index: 99;
}

#add-items {
  font-size: large;
  position: absolute;
  top: 75px;
  right: 20px;
  display: grid;
  justify-items: end;
  grid-auto-flow: column;
  cursor: pointer;
}

#add-items > svg {
  color: red;
}

#ops-items-holder {
  width: 50%;
  position: absolute;
  top: 5%;
  left: 25%;
  margin: auto;
  padding: 40px;
  box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.5);
  border: 1px solid rgba(128, 128, 128, 0.414);
  border-radius: 10px;
  background-color: white;
  z-index: 100;
}

.history-year {
  width: 120px;
  background-color: brown;
  color: white;
  /* letter-spacing: 10px; */
  text-align: center;
  /* padding: 5px; */
  font-family: "Abril Fatface", serif;
  font-size: 3rem;
  /* line-height: 1; */
  border-radius: 5px;
}
.history-year::before {
  font-weight: bold;
  font-size: 3rem;
  margin-right: 0.5rem;
  font-family: "Abril Fatface", serif;
}

.history-text {
  text-align: left;
  margin-left: 10%;
  line-height: 1.4;
}

.history-text > p {
  border-left-width: 4px;
  border-left-color: #932207;
  border-left-style: dotted;
  padding: 10px;
  padding-left: 10%;
  background-color: rgba(244, 165, 96, 0.332);
  margin-right: 20px;
}

.history-img-cards {
  width: 50%;
}

.picture-roll {
  width: 30%;
  border-radius: 10%;
  margin: 5px;
  object-fit: cover;
  transition: transform 0.5s ease;
  transition-delay: 1s;
}

.picture-roll:hover {
  -webkit-transform: scale(2);
  transform: scale(2);
  z-index: 100;
}

.prayer-holder {
  margin-bottom: 40px;
  min-height: 50vh;
  max-height: 90vh;
  overflow: scroll;
}

.prayer-holder > div {
  min-height: 50vh;
  height: 70vh;
  margin: auto;
}
.prayer-holder > div > div {
  /* min-height: 50vh; */
  height: 70vh;
  display: flex !important;
  align-items: center !important;
  overflow: scroll;
}

.prayer-text {
  font-family: "open sans";
  /* font-size: x-large; */
  font-size: 3vw;
  line-height: 1.4;
  /* overflow-y: scroll; */
  text-overflow: clip;
  max-height: 70vh;
}

/* #carousel-praise {
  margin: 100px auto auto;
  color: blue;
  min-height: 50vh;
  height: 50vh;
  width: 90vw !important;
} */

.praise-text {
  font: 800 64px/64px "open sans";
  /* font-family: "playfair display";
  font-size: 84px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: normal;
  line-height: 100px;
  text-transform: none;
  color: #242526; */
  color: #222;
  /* font: 800 64px/64px "open sans"; */
  /* margin: 0 0 0 0; */
  /* text-align: center; */
  text-transform: uppercase;
  /* height: 250px; */
  width: 80vw !important;
  overflow: scroll;
  word-wrap: break-word;
}
/* .history-img-cards:nth-child(even):before {
  content: "XXX";
  background-color: #61dafb;
  border-radius: 50%;
  margin: 10px;
}
.history-img-cards:nth-child(odd):before {
  content: "YYY";
  background-color: #932207;
  border-radius: 100%;
  margin: 20px;
  display: inline-flex;
} */

/* This is for timeline like a worm */
/* .history-text > p {
  border-top: 2px dashed;
  border-color: blue !important;
  margin: 0;
  padding: 30px;
  counter-increment: section ;
  position: relative;
}

.history-text > p:nth-child(even):before {
  content: counter(section);
  right: 100%;
  margin-right: -20px;
  position: absolute;
  border-radius: 50%;
  padding: 10px;
  height: 20px;
  width: 20px;
  background-color: blue;
  text-align: center;
  color: white;
  font-size: 110%;
}

.history-text > p:nth-child(odd):before {
  content: counter(section);
  left: 100%;
  margin-left: -20px;
  position: absolute;
  border-radius: 50%;
  padding: 10px;
  height: 20px;
  width: 20px;
  background-color: blue;
  text-align: center;
  color: white;
  font-size: 110%;
}

.history-text > p:nth-child(even) {
  border-left: 2px dashed;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-right: 30px;
  padding-right: 0;
}

.history-text > p:nth-child(odd) {
  border-right: 2px dashed;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-left: 30px;
  padding-left: 0;
}

.history-text > p:first-child {
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.history-text > p:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
} */

.wfh1 {
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
  /* font-family: Mangogrotesque, sans-serif; */
  font-size: 200px;
  font-weight: 900;
  line-height: 0.9;
}

.header-display-outline-blue {
  color: rgba(0, 0, 255, 0.1);
  /* rgba(255, 255, 255, 0.75); */
  text-align: center;
  -webkit-text-stroke-width: 0.0008em;
  -webkit-text-stroke-color: var(--deep-sky-blue);
  margin-top: -20px;
  margin-bottom: 0;
  font-size: 28vw;
  line-height: 0.9;
  display: flex;
}

.header-display-outline-orange {
  color: rgba(0, 0, 0, 0);
  text-align: center;
  -webkit-text-stroke-width: 0.0005em;
  -webkit-text-stroke-color: #ff7372;
  mix-blend-mode: multiply;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 28vw;
  line-height: 0.9;
  display: flex;
}

.header-display-outline-yellow {
  opacity: 1;
  color: rgba(0, 0, 0, 0);
  text-align: center;
  -webkit-text-stroke-width: 0.0003em;
  -webkit-text-stroke-color: #fff89f;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 38vw;
  line-height: 0.9;
  display: flex;
}

.cursive {
  text-align: left;
  text-transform: none;
  margin-bottom: 60px;
  font-family: "Testimonia", sans-serif;
  font-size: 7.4rem;
  font-weight: 400;
  line-height: 1.4;
}

.cursive.header {
  z-index: 3;
  text-align: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 4rem;
  display: flex;
  position: absolute;
  top: -10%;
  bottom: auto;
  left: 10%;
  right: auto;
  overflow: visible;
}
.header-section-container {
  /* width: 80vw; */
  height: 50vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
  overflow: visible;
}
.header-letter-wrapper {
  z-index: 0;
  width: 100%;
  flex-direction: row;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  /* position: fixed; */
  top: auto;
  bottom: auto;
  left: 0%;
  right: 0%;
  height: 30vh;
}

.header-letter-wrapper.blue-outline-text {
  z-index: 1;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  position: absolute;
  top: -2%;
  bottom: 0%;
  left: 1%;
  right: 0%;
}

.header-letter-wrapper.orange-outline-text {
  z-index: -1;
  width: 100%;
  display: flex;
  position: absolute;
  top: 19px;
  bottom: auto;
  left: -18px;
  right: auto;
}

.header-letter-wrapper.yellow-outline-text {
  z-index: -2;
  width: 100%;
  flex-direction: row;
  display: flex;
  position: relative;
  top: 41px;
  bottom: auto;
  left: -39px;
  right: auto;
}

.w-container {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.w-container:before,
.w-container:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-container:after {
  clear: both;
}

.w-container .w-row {
  margin-left: -10px;
  margin-right: -10px;
}

.w-row:before,
.w-row:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-row:after {
  clear: both;
}

.w-row .w-row {
  margin-left: 0;
  margin-right: 0;
}

.w-col {
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.w-col .w-col {
  padding-left: 0;
  padding-right: 0;
}

.bible-verse-hero {
  z-index: 0;
  width: 100%;
  height: auto;
  /* background-image: url("https://assets.website-files.com/636510d912722f432d1f7470/63673faff31a18788a33e606_illustration-bird.svg"); */
  background-position: 100% 130%;
  background-repeat: no-repeat;
  background-size: 30vw;
  background-attachment: fixed;
  justify-content: center;
  align-items: flex-start;
  /* margin-top: 100vh; */
  margin-bottom: 0;
  padding-top: 9vh;
  padding-bottom: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.h3.cursive {
  text-align: left;
  text-transform: none;
  margin-bottom: 60px;
  font-family: "Testimonia", sans-serif;
  font-size: 7.4rem;
  font-weight: 400;
  line-height: 1.4;
}

.h3.cursive.header {
  z-index: 3;
  text-align: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 4rem;
  display: flex;
  position: absolute;
  top: -10%;
  bottom: auto;
  left: 10%;
  right: auto;
  overflow: visible;
}

.h3.cursive.header._404 {
  text-align: center;
}

.h3.cursive.header.studio {
  z-index: 3;
  top: auto;
  bottom: 10%;
  left: auto;
  right: 10%;
}

.world {
  background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSmm-W6t6sNN77Q4Pe1rdTNogwLGXcq2ZKUw&usqp=CAU);
  background-size: cover;
  background-position: 25%;
  background-repeat: no-repeat;
  -webkit-text-fill-color: transparent;
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 80px;
}
/* .wfh1.display.r {
  background-image: url("https://assets.website-files.com/636510d912722f432d1f7470/6367d1283a6cea2de8b50e10_1%202.jpg");
  background-position: 40%;
}

.wfh1.display.e {
  background-image: url("https://assets.website-files.com/636510d912722f432d1f7470/6367d128fe9d3c136aa7b8ec_1%203.jpg");
  background-position: 40% 60%;
  background-size: cover;
}

.wfh1.display.a {
  background-image: url("https://assets.website-files.com/636510d912722f432d1f7470/6367d129caac03a5da0640d8_9.jpg");
  background-position: 50%;
}

.wfh1.display.t {
  background-image: url("https://assets.website-files.com/636510d912722f432d1f7470/6367d1293a6cea08b7b50e12_Services-Apps.jpg");
  background-position: 80%;
}

.wfh1.display.i {
  background-image: url("https://assets.website-files.com/636510d912722f432d1f7470/6367d129ca5ec4bc35c075b4_tabby-byte-illustration.jpg");
  background-position: 66%;
  background-size: cover;
}

.wfh1.display.v {
  background-image: url("https://assets.website-files.com/636510d912722f432d1f7470/6367d129cb0f68129c752c52_RMB_Credit_View_1.jpg");
  background-position: 80%;
  background-size: cover;
} */
.title {
  position: absolute;
  top: 20%;
}
.give-images {
  width: 20%;
  padding: 20px;
}

/* center the blockquote in the page */
.blockquote-wrapper {
  display: flex;
  /* height: 100vh; */
  padding: 0 20px;
  background-color: #292a2b;
}

/* Blockquote main style */
.blockquote {
  position: relative;
  font-family: "Barlow Condensed", sans-serif;
  max-width: 620px;
  margin: 80px auto;
  align-self: center;
}

/* Blockquote header */
.blockquote h1 {
  font-family: "Abril Fatface", cursive;
  position: relative; /* for pseudos */
  color: #e74848;
  font-size: 2.8rem;
  font-weight: normal;
  line-height: 1;
  margin: 0;
  border: 2px solid #fff;
  border: solid 2px;
  border-radius: 20px;
  padding: 25px;
}

/* Blockquote right double quotes */
.blockquote h1:after {
  content: "";
  position: absolute;
  border: 2px solid #e74848;
  border-radius: 0 50px 0 0;
  width: 60px;
  height: 60px;
  bottom: -62px;
  left: 50px;
  border-bottom: none;
  border-left: none;
  z-index: 3;
}

.blockquote h1:before {
  content: "";
  position: absolute;
  width: 80px;
  border: 6px solid #292a2b;
  bottom: -3px;
  left: 50px;
  z-index: 2;
}

/* Blockquote subheader */
.blockquote h4 {
  position: relative;
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
  padding-top: 15px;
  z-index: 1;
  margin-left: 150px;
  padding-left: 12px;
}

.blockquote h4:first-letter {
  margin-left: -12px;
}

.nblockquote {
  color: red;
  font-family: "Testimonia", Tahoma, sans-serif;
  font-size: 100px;
  width: 60%;
  margin: 0 auto;
}
.nblockquote h1 {
  font-size: 4rem;
}
.nblockquote p {
  font-style: italic;
  margin-bottom: 0;
}

.nblockquote p::before,
.nblockquote p::after {
  content: "“";
  font-family: Georgia;
  font-size: 8rem;
  margin: -2rem 0 0 -4rem;
  position: absolute;
  opacity: 0.5;
}

.nblockquote p::after {
  content: "”";
  margin: -1rem -4rem 0 0;
}

.nblockquote cite {
  font-size: 1.5rem;
}

.outer {
  display: flex;
  overflow-x: scroll;
}

.middle {
  display: flex;
  width: 100%;
}

.innersm {
  flex: 0 0 25%;
  overflow: scroll;
  overflow-anchor: none;
}

.inner {
  flex: 0 0 30%;
  height: 60vh;
  overflow: scroll;
  margin: 10px;
  line-height: 1.5;
}

#published-author-dt {
  font-size: small;
  text-align: right;
  font-style: oblique;
  margin-right: 5%;
  color: purple;
}

#sermon-abstract {
  text-align: justify;
  padding: 20px;
  color: brown;
  height: 25vh;
  overflow: scroll;
}

/* About page */
#our-incumbent > div > div > img {
  width: 50%;
}

/* admin page */
.admin-container {
  border: 1px solid gray;
  padding: 20px;
  margin: 50px;
}
