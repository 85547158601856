@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400&display=swap);

.splide__container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: -moz-box;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  -moz-box-align: center;
  align-items: center;
  display: -moz-box;
  display: flex;
  flex-wrap: wrap;
  -moz-box-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  outline: none;
  position: relative;
  visibility: hidden;
}

.splide.is-initialized,
.splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  outline: none;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__slider {
  position: relative;
}

.splide__spinner {
  -webkit-animation: splide-loading 1s linear infinite;
  -moz-animation: splide-loading 1s linear infinite;
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@-webkit-keyframes splide-loading {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-moz-keyframes splide-loading {
  0% {
    -moz-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -moz-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes splide-loading {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.splide--draggable > .splide__slider > .splide__track,
.splide--draggable > .splide__track {
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.splide--fade > .splide__slider > .splide__track > .splide__list,
.splide--fade > .splide__track > .splide__list {
  display: block;
}

.splide--fade
  > .splide__slider
  > .splide__track
  > .splide__list
  > .splide__slide,
.splide--fade > .splide__track > .splide__list > .splide__slide {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 0;
}

.splide--fade
  > .splide__slider
  > .splide__track
  > .splide__list
  > .splide__slide.is-active,
.splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  opacity: 1;
  position: relative;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide--ttb.is-active > .splide__slider > .splide__track > .splide__list,
.splide--ttb.is-active > .splide__track > .splide__list {
  display: block;
}

.splide__arrow {
  -moz-box-align: center;
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: -moz-box;
  display: flex;
  height: 2em;
  -moz-box-pack: center;
  justify-content: center;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 2em;
  z-index: 1;
}

.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}

.splide__arrow:hover {
  opacity: 0.9;
}

.splide__arrow:focus {
  outline: none;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide__pagination {
  bottom: 0.5em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
}

.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: -webkit-transform 0.2s linear;
  -moz-transition: transform 0.2s linear, -moz-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear,
    -moz-transform 0.2s linear;
  width: 8px;
}

.splide__pagination__page.is-active {
  background: #fff;
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  transform: scale(1.4);
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: 0.9;
}

.splide__pagination__page:focus {
  outline: none;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide--nav
  > .splide__slider
  > .splide__track
  > .splide__list
  > .splide__slide,
.splide--nav > .splide__track > .splide__list > .splide__slide {
  border: 3px solid transparent;
  cursor: pointer;
}

.splide--nav
  > .splide__slider
  > .splide__track
  > .splide__list
  > .splide__slide.is-active,
.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide--nav
  > .splide__slider
  > .splide__track
  > .splide__list
  > .splide__slide:focus,
.splide--nav > .splide__track > .splide__list > .splide__slide:focus {
  outline: none;
}

.splide--rtl > .splide__arrows .splide__arrow--prev,
.splide--rtl
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow--prev,
.splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide--rtl > .splide__arrows .splide__arrow--prev svg,
.splide--rtl
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow--prev
  svg,
.splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev svg {
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  transform: scaleX(1);
}

.splide--rtl > .splide__arrows .splide__arrow--next,
.splide--rtl
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow--next,
.splide--rtl > .splide__track > .splide__arrows .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide--rtl > .splide__arrows .splide__arrow--next svg,
.splide--rtl
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow--next
  svg,
.splide--rtl > .splide__track > .splide__arrows .splide__arrow--next svg {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  transform: scaleX(-1);
}

.splide--ttb > .splide__arrows .splide__arrow,
.splide--ttb
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow,
.splide--ttb > .splide__track > .splide__arrows .splide__arrow {
  left: 50%;
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  transform: translate(-50%);
}

.splide--ttb > .splide__arrows .splide__arrow--prev,
.splide--ttb
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow--prev,
.splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev {
  top: 1em;
}

.splide--ttb > .splide__arrows .splide__arrow--prev svg,
.splide--ttb
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow--prev
  svg,
.splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev svg {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.splide--ttb > .splide__arrows .splide__arrow--next,
.splide--ttb
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow--next,
.splide--ttb > .splide__track > .splide__arrows .splide__arrow--next {
  bottom: 1em;
  top: auto;
}

.splide--ttb > .splide__arrows .splide__arrow--next svg,
.splide--ttb
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow--next
  svg,
.splide--ttb > .splide__track > .splide__arrows .splide__arrow--next svg {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
}

.splide--ttb > .splide__pagination,
.splide--ttb > .splide__slider > .splide__pagination {
  bottom: 0;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  left: auto;
  padding: 1em 0;
  right: 0.5em;
  top: 0;
}

/* a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
} */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.caseStudy-nextCase-title {
  font-size: 4rem;
  font-weight: 300;
  line-height: 6rem;
  letter-spacing: -0.03rem;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .caseStudy-nextCase-title {
    font-size: 10.5rem;
    line-height: 10.5rem;
  }
}

.banner-text,
.caseStudy-cyphers-description,
.caseStudy-teamMember,
.caseStudyCover-description,
.caseStudyCover-text,
.description-text,
.jobLinks-job-description,
.members-position,
.members-text,
.metrics .metrics-title,
.metrics .metrics__item-container .metric-item,
.metrics .metrics__item-container .metric-item a span,
.metrics .metrics__item-container .metric-item h2,
.pageHeader-subtitle,
.pageHeader-text,
.sectionTitle-description,
.servicesDistributor .list-title,
.servicesDistributor li,
.sneakPeak-subtitle,
.video-featured-text {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8rem;
}

@media (min-width: 768px) {
  .banner-text,
  .caseStudy-cyphers-description,
  .caseStudy-teamMember,
  .caseStudyCover-description,
  .caseStudyCover-text,
  .description-text,
  .jobLinks-job-description,
  .members-position,
  .members-text,
  .metrics .metrics-title,
  .metrics .metrics__item-container .metric-item,
  .metrics .metrics__item-container .metric-item a span,
  .metrics .metrics__item-container .metric-item h2,
  .pageHeader-subtitle,
  .pageHeader-text,
  .sectionTitle-description,
  .servicesDistributor .list-title,
  .servicesDistributor li,
  .sneakPeak-subtitle,
  .video-featured-text {
    font-size: 1.125rem;
    line-height: 2rem;
  }
}

.caseStudy-headerTitle,
.caseStudy-nextCase-client,
.caseStudy-nextCase-description,
.footer-brand,
.footer-info,
.homeSlideItem-category,
.pageHeader-heading {
  font-size: 1rem;
  font-weight: 400;
  line-height: 2rem;
}

.mousePointer-text,
.relatedLink-category,
.relatedLink-titleCaption {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-transform: uppercase;
}

.footer-privacy .link-text,
.menuMobile-heading,
.servicesDistributor .item-title span {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2rem;
}

.about-introduction-text,
.culture-identity,
.description-claim,
.home .home-subtitle,
.menuDesktop-claim,
.menuMobile .link-text,
.pageHeader-description,
.testimony-text {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.5rem;
}

@media (min-width: 768px) {
  .about-introduction-text,
  .culture-identity,
  .description-claim,
  .home .home-subtitle,
  .menuDesktop-claim,
  .menuMobile .link-text,
  .pageHeader-description,
  .testimony-text {
    font-size: 2rem;
    line-height: 3rem;
  }
}

.jobLinks-job-title,
.relatedLink-titleText,
.servicesList-service-title {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.5rem;
}

@media (min-width: 768px) {
  .jobLinks-job-title,
  .relatedLink-titleText,
  .servicesList-service-title {
    font-size: 2.5rem;
    line-height: 4rem;
    letter-spacing: -0.03rem;
  }
}

.homeHero-claim {
  font-size: 2.25rem;
  line-height: 3rem;
  font-weight: 300;
  letter-spacing: -0.04em;
}

@media (min-width: 768px) {
  .homeHero-claim {
    font-size: 3rem;
    line-height: 4rem;
  }
}

.about-subtitle,
.about .areas-title,
.caseStudy-subtitle,
.culture-subtitle,
.description-title,
.homeCarousel-title,
.members-title,
.servicesDistributor .servicesDistributor-title,
.videoSlide-title {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .about-subtitle,
  .about .areas-title,
  .caseStudy-subtitle,
  .culture-subtitle,
  .description-title,
  .homeCarousel-title,
  .members-title,
  .servicesDistributor .servicesDistributor-title,
  .videoSlide-title {
    font-size: 1.25rem;
    line-height: 2rem;
  }
}

.link-text {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.about-conclusionClaim,
.caseStudy-conclusionClaim,
.caseStudy-cyphers-cypher,
.culture-membersTitle,
.process-section,
.sectionTitle-title,
.servicesDistributor .item-title,
.strengths-words {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 3.5rem;
  letter-spacing: -0.03rem;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .about-conclusionClaim,
  .caseStudy-conclusionClaim,
  .caseStudy-cyphers-cypher,
  .culture-membersTitle,
  .process-section,
  .sectionTitle-title,
  .servicesDistributor .item-title,
  .strengths-words {
    font-size: 5.5rem;
    line-height: 7rem;
  }
}

@media (min-width: 1680px) {
  .about-conclusionClaim,
  .caseStudy-conclusionClaim,
  .caseStudy-cyphers-cypher,
  .culture-membersTitle,
  .process-section,
  .sectionTitle-title,
  .servicesDistributor .item-title,
  .strengths-words {
    font-size: 6rem;
    line-height: 7.5rem;
  }
}

.caseStudyCover-title {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 2rem;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .caseStudyCover-title {
    font-size: 1.5rem;
    line-height: 2.5rem;
    letter-spacing: -0.03rem;
  }
}

.homeSlideItem-description {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 2rem;
}

.pageHeader-claim,
.sneakPeak-title {
  font-size: 3rem;
  font-weight: 300;
  line-height: 4rem;
  letter-spacing: -0.03rem;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .pageHeader-claim,
  .sneakPeak-title {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 7rem;
    letter-spacing: 0;
  }
}

.caseStudy-headerSubtitle,
.footer-claim-title {
  font-size: 2rem;
  font-weight: 300;
  line-height: 3rem;
  letter-spacing: -0.03rem;
}

@media (min-width: 768px) {
  .caseStudy-headerSubtitle,
  .footer-claim-title {
    font-size: 3rem;
    font-weight: 300;
    line-height: 4.5rem;
    letter-spacing: 0;
  }
}

html {
  font-family: Saol Display, Inter, sans-serif;
  font-size: 16;
}

body {
  display: block;
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  height: 100vh;
  line-height: 1;
  /* height: -webkit-calc(var(--vh,1vh) * 100);
    height: -moz-calc(var(--vh,1vh) * 100);
    height: calc(var(--vh, 1vh) * 100);*/
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body ::-moz-selection {
  color: #fff;
  background-color: #94a479;
}

body ::selection {
  color: #fff;
  background-color: #94a479;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: Evander;
  text-transform: uppercase;
}

dd,
dl,
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

/* a {
  text-decoration: none;
  color: inherit;
  cursor: none;
} */

/* a:focus,
a:hover {
  outline: none;
} */

button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: none;
}

/* img {
    max-width: 100%
} */

.about,
.about-introduction {
  display: block;
  position: relative;
}

.about-introduction {
  margin: 80px auto;
}

.about-introduction .link {
  margin-top: 40px;
}

.about-introduction-text {
  display: block;
  position: relative;
  margin: 0 auto;
  color: #333;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.about .description,
.about .relatedLinkContainer {
  margin-top: 80px;
}

.about-subtitle {
  display: block;
  position: relative;
  margin: 48px 0 24px;
  color: #333;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.about-subtitle .about-subtitle-caption {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: start;
  align-items: flex-start;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-box-pack: start;
  justify-content: flex-start;
  position: relative;
  margin-top: 16px;
  min-width: 100%;
  font-size: 16px;
  line-height: 32px;
  text-transform: none;
  color: #999;
}

.about-subtitle .about-subtitle-caption span {
  display: block;
  position: relative;
  white-space: nowrap;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.about-subtitle .about-subtitle-caption span.is-active {
  color: #000;
}

.about-subtitle .about-subtitle-caption span + span:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 0 8px;
  width: 24px;
  height: 1px;
  background-color: #c4c4c4;
}

.about .sectionWrapper + .servicesList {
  margin-top: 0;
}

.about-conclusionClaim {
  display: block;
  position: relative;
  margin: 80px auto;
  color: #333;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

@media (min-width: 375px) {
  .about .about-subtitle-caption span + span:before {
    width: 52px;
  }
}

@media (min-width: 768px) {
  .about-conclusionClaim,
  .about-introduction {
    margin: 144px auto;
  }

  .about-introduction .link {
    margin-top: 56px;
  }

  .about .description,
  .about .relatedLinkContainer {
    margin-top: 144px;
  }

  .about-subtitle {
    display: -moz-box;
    display: flex;
    -moz-box-align: start;
    align-items: flex-start;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
    flex-wrap: nowrap;
    -moz-box-pack: justify;
    justify-content: space-between;
    margin: 144px 0 88px;
  }

  .about .about-subtitle-caption {
    -moz-box-pack: end;
    justify-content: flex-end;
    margin-top: 0;
    min-width: 0;
  }

  .about .about-subtitle-caption span + span:before {
    margin: 0 16px;
  }
}

@media (min-width: 1024px) {
  .about-conclusionClaim {
    padding-right: 25%;
  }
}

@media (min-width: 1280px) {
  .about-introduction-text,
  .about-introduction .link {
    margin-left: 8.33%;
    margin-right: 16.66%;
  }
}

@media (min-width: 1440px) {
  .about-conclusionClaim {
    padding-right: 50%;
  }
}

.about .caseStudyContainer {
  margin-top: 0;
}

.about .areas-title {
  margin-bottom: calc(-80px + 48px);
  margin-top: 64px;
}

@media (min-width: 768px) {
  .about .areas-title {
    margin-bottom: calc(-144px + 70px);
    margin-top: 144px;
  }
}

.mainLayout.is-dark .about-conclusionClaim,
.mainLayout.is-dark .about-introduction-text,
.mainLayout.is-dark .about-subtitle {
  color: #fff;
}

.mainLayout.is-dark .about .about-subtitle-caption {
  color: #94a479;
}

.mainLayout.is-dark .about .about-subtitle-caption span.is-active {
  color: #fff;
}

.process {
  display: block;
  position: relative;
  top: 0 !important;
  left: 0 !important;
  min-width: 100vw;
  min-height: 100vh;
  max-height: 100vh !important;
  min-height: -webkit-calc(var(--vh, 1vh) * 100);
  min-height: -moz-calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: -webkit-calc(var(--vh, 1vh) * 100) !important;
  max-height: -moz-calc(var(--vh, 1vh) * 100) !important;
  max-height: calc(var(--vh, 1vh) * 100) !important;
  text-align: center;
  color: #333;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
  pointer-events: none;
  z-index: 0;
}

.process .sectionWrapper {
  height: 100%;
}

.process .about-subtitle {
  margin: 0;
  padding: 48px 0 24px;
}

.process-section,
.process .about-subtitle {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.process-section {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 0;
}

.process-section .sectionWrapper {
  display: block;
  position: relative;
  margin-top: 50vh;
}

.process-section-icon {
  display: block;
  position: relative;
  width: 168px;
  height: 168px;
  margin-top: -168px;
}

.process-section-icon .icon {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.process-section-text {
  display: block;
  position: relative;
  margin-top: 16px;
}

@media (min-width: 768px) {
  .process-subtitle {
    padding: 144px 0 88px;
  }

  .process-section-icon {
    width: 192px;
    height: 192px;
    margin-top: -192px;
  }

  .process-section-text {
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .process {
    max-width: 83.33%;
  }
}

.mainLayout.is-dark .process {
  color: #fff;
}

.servicesList {
  display: block;
  position: relative;
  margin-top: 80px;
  color: #333;
}

.servicesList-container {
  margin-top: 32px;
}

.servicesList-container,
.servicesList-images {
  display: block;
  position: relative;
  overflow: hidden;
}

.servicesList-images {
  margin: 0 24px 32px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.servicesList-images .servicesList-imageWrapper + .servicesList-imageWrapper {
  display: none;
}

.servicesList-imageWrapper {
  height: 61.87vw;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.servicesList-imageWrapper .picture,
.servicesList-imageWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.servicesList-service {
  display: block;
  position: relative;
  -webkit-transform: perspective(400px) rotateX(0deg);
  -moz-transform: perspective(400px) rotateX(0deg);
  transform: perspective(400px) rotateX(0deg);
  -webkit-transform-origin: bottom;
  -moz-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.servicesList-service:after {
  content: "";
  display: block;
  position: relative;
  margin-top: 24px;
  margin-left: 24px;
  width: -webkit-calc(100% - 48px);
  width: -moz-calc(100% - 48px);
  width: calc(100% - 48px);
  height: 1px;
  background-color: #c4c4c4;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.servicesList-service + .servicesList-service {
  margin-top: 24px;
}

.servicesList-service .link {
  margin-top: 8px;
}

.servicesList-service-title {
  display: block;
  position: relative;
  margin: 0 auto;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .servicesList-imageWrapper .picture {
    -webkit-transform: none !important;
    -moz-transform: none !important;
    transform: none !important;
  }
}

@media (min-width: 768px) {
  .servicesList {
    margin-top: 144px;
  }

  .servicesList .sectionWrapper {
    text-align: center;
  }

  .servicesList-service-title {
    width: 83.33%;
  }

  .servicesList-container {
    margin-top: 88px;
  }

  .servicesList-service {
    padding-bottom: 0;
    border-bottom: 0;
  }

  .servicesList-service + .servicesList-service {
    margin-top: 88px;
  }

  .servicesList-service:after {
    display: none;
  }

  .servicesList-service .link {
    margin-top: 16px;
  }
}

@media (min-width: 1024px) {
  .servicesList-images {
    display: grid;
    grid-template-columns: 1fr 50% 1fr;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
  }

  .servicesList-images .servicesList-imageWrapper + .servicesList-imageWrapper {
    display: block;
  }

  .servicesList-imageWrapper {
    margin: 0;
    padding-top: 0;
    height: auto;
  }

  .servicesList-imageWrapper .picture,
  .servicesList-imageWrapper img {
    height: auto;
  }

  .servicesList-imageWrapper {
    grid-row: 1/2;
    max-width: max(25%, 20vw);
  }

  .servicesList-imageWrapper .picture {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
    -webkit-transform-origin: top center;
    -moz-transform-origin: top center;
    transform-origin: top center;
  }

  .servicesList-imageWrapper:first-child {
    grid-column: 3/4;
    align-self: start;
    justify-self: end;
    -webkit-transform: translateY(20%);
    -moz-transform: translateY(20%);
    transform: translateY(20%);
  }

  .is-reverse .servicesList-imageWrapper:first-child {
    grid-column: 1/2;
    justify-self: start;
  }

  .is-centered .servicesList-imageWrapper:first-child {
    justify-self: end;
    align-self: center;
  }

  .is-reverse.is-centered .servicesList-imageWrapper:first-child {
    justify-self: start;
  }

  .servicesList-imageWrapper:nth-child(2) {
    grid-column: 1/2;
    align-self: end;
    justify-self: start;
    -webkit-transform: translateY(30%);
    -moz-transform: translateY(30%);
    transform: translateY(30%);
  }

  .is-reverse .servicesList-imageWrapper:nth-child(2) {
    grid-column: 3/4;
    justify-self: end;
  }

  .servicesList-service-title {
    width: 50%;
  }
}

.mainLayout.is-dark .servicesList {
  color: #fff;
}

.mainLayout.is-dark .servicesList .servicesList-service:after {
  background-color: #333;
}

.servicesTitle {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  padding: 16px 0;
  width: 100%;
  background-color: hsla(0, 0%, 100%, 0.9);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: background-color 0.3s ease, -webkit-transform 0.3s ease;
  transition: background-color 0.3s ease, -webkit-transform 0.3s ease;
  -moz-transition: background-color 0.3s ease, transform 0.3s ease,
    -moz-transform 0.3s ease;
  transition: background-color 0.3s ease, transform 0.3s ease;
  transition: background-color 0.3s ease, transform 0.3s ease,
    -webkit-transform 0.3s ease, -moz-transform 0.3s ease;
  z-index: 5;
}

.servicesTitle.is-hidden {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  transform: translateY(-100%);
}

.servicesTitle .about-subtitle {
  margin: 0;
  overflow: hidden;
}

.servicesTitle .about-subtitle-title {
  display: none;
}

.servicesTitle .about-subtitle-caption {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-box-pack: center;
  justify-content: center;
  position: relative;
  margin-top: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .servicesTitle .about-subtitle-title {
    display: block;
  }

  .servicesTitle .about-subtitle-caption {
    -moz-box-pack: end;
    justify-content: flex-end;
  }
}

@media (min-width: 1024px) {
  .servicesTitle + .servicesList {
    padding-top: 56px;
  }
}

.mainLayout.is-dark .servicesTitle {
  background-color: rgba(0, 0, 0, 0.9);
}

.banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px 0;
  background: #94a479;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -moz-transition: transform 0.3s ease, -moz-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease,
    -moz-transform 0.3s ease;
  z-index: 5;
}

.banner.is-open {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
}

.banner .sectionWrapper {
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
}

/* .banner-text {
  color: #fff;
  display: none;
}

.banner .link .link-text {
  color: #fff;
}

.banner .link .iconArrow path {
  fill: #fff;
}

.banner .link:hover .link-text {
  color: #505f43;
}

.banner .link:hover .iconArrow path {
  fill: #505f43;
} */

@media (min-width: 1024px) {
  .banner .sectionWrapper {
    -moz-box-pack: justify;
    justify-content: space-between;
  }

  .banner-text {
    display: block;
  }
}

/* .mainLayout.is-dark .banner .link .link-text {
  color: #fff;
}

.mainLayout.is-dark .banner .link .iconArrow path {
  fill: #fff;
}

.mainLayout.is-dark .banner .link:hover .link-text {
  color: #505f43;
}

.mainLayout.is-dark .banner .link:hover .iconArrow path {
  fill: #505f43;
} */

.scrollCarousel {
  display: block;
  position: relative;
  width: 100%;
  height: 61.87vw;
  overflow: hidden;
}

.scrollCarousel-wrapper {
  flex-wrap: block;
  position: relative;
  width: 125%;
  height: 100%;
}

.scrollCarousel .carousel,
.scrollCarousel .splide {
  width: 100%;
  height: 100%;
  overflow: visible;
}

.scrollCarousel .splide__track {
  height: 100%;
  overflow: visible;
  cursor: none;
}

.scrollCarousel .splide__list {
  display: -moz-inline-box !important;
  display: inline-flex !important;
  pointer-events: none;
}

.scrollCarousel .splide__slide {
  display: block;
  position: relative;
  width: auto;
  height: 100%;
  overflow: hidden;
}

.scrollCarousel .picture,
.scrollCarousel .video {
  display: block;
  position: relative;
  width: auto;
  height: 100%;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transform-origin: left;
  -moz-transform-origin: left;
  transform-origin: left;
}

.scrollCarousel img,
.scrollCarousel video {
  max-width: none;
  max-height: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .scrollCarousel {
    height: 420px;
  }
}

.carouselGallery {
  display: block;
  position: relative;
  height: 556px;
}

.carouselGallery .carousel,
.carouselGallery .carouselGallery-slide,
.carouselGallery .splide,
.carouselGallery .splide__slide,
.carouselGallery .splide__track {
  display: block;
  position: relative;
  height: 100%;
}

.carouselGallery .splide__list {
  pointer-events: none;
}

.carouselGallery .splide__slide {
  display: block;
  position: relative;
  top: 50%;
  width: auto;
  height: 80.33%;
  -webkit-transition: height 0.3s ease;
  -moz-transition: height 0.3s ease;
  transition: height 0.3s ease;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.carouselGallery .splide__slide.is-active,
.carouselGallery .splide__slide.is-visible {
  height: 100%;
}

.carouselGallery .carouselGallery-slide div {
  display: block;
  position: relative;
  width: 210px;
  height: -webkit-calc(50% - 8px);
  height: -moz-calc(50% - 8px);
  height: calc(50% - 8px);
}

.carouselGallery .carouselGallery-slide div + div {
  margin-top: 16px;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  transform-origin: top;
}

.carouselGallery .carouselGallery-slide div .picture {
  display: none;
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.carouselGallery .carouselGallery-slide div img {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.culture .layoutSection + .layoutSection {
  margin-top: 144px;
}

.culture .description {
  margin-top: 80px;
}

@media (min-width: 1024px) {
  .culture .description .description-content {
    min-width: 66.66%;
    max-width: 66.66%;
  }

  .culture-identity {
    text-align: right;
  }
}

.culture .strengths {
  overflow: hidden;
}

.culture-subtitle {
  margin: 48px 0 24px;
}

.culture-membersTitle,
.culture-subtitle {
  display: block;
  position: relative;
  color: #333;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.culture-membersTitle {
  margin: 0 auto 80px;
  max-width: 688px;
  text-align: left;
}

@media (min-width: 768px) {
  .culture-subtitle {
    display: -moz-box;
    display: flex;
    -moz-box-align: start;
    align-items: flex-start;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
    flex-wrap: nowrap;
    -moz-box-pack: justify;
    justify-content: space-between;
    margin: 144px 0 88px;
  }

  .culture .caseStudyContainer {
    margin-top: 0;
  }

  .culture-membersTitle {
    text-align: center;
  }
}

.mainLayout.is-dark .culture-membersTitle,
.mainLayout.is-dark .culture-subtitle,
.mainLayout.is-dark .culture .jobLinks,
.mainLayout.is-dark .culture .sectionTitle {
  color: #fff;
}

.mainLayout.is-dark .culture .sectionTitle .sectionTitle-description {
  color: #999;
}

.cultureGallery {
  margin-top: 144px;
}

.cultureMasonry {
  margin-bottom: -64px;
}

.cultureMasonry-grid {
  display: -moz-box;
  display: flex;
  margin-left: -64px;
  width: auto;
}

.cultureMasonry-grid_column {
  padding-left: 64px;
  background-clip: padding-box;
}

.cultureMasonry-grid_column:first-child {
  margin-top: 64px;
}

.cultureMasonry-grid_column:nth-child(3) {
  margin-top: 128px;
}

.cultureMasonry .picture {
  margin-bottom: 64px;
}

.cultureMasonry .picture img {
  width: 100%;
}

.members {
  color: #333;
}

.members-member + .members-member {
  margin-top: 48px;
}

.members .picture {
  margin-bottom: 16px;
}

.members .picture img {
  width: 100%;
}

.members-title {
  margin-bottom: 8px;
  text-transform: uppercase;
}

.members-position {
  margin-bottom: 8px;
  color: #999;
}

.members-text {
  max-width: 515px;
}

.members-text h1,
.members-text h2,
.members-text h3,
.members-text h4,
.members-text h5,
.members-text h6 {
  color: #999;
}

.members-text h1 + *,
.members-text h2 + *,
.members-text h3 + *,
.members-text h4 + *,
.members-text h5 + *,
.members-text h6 + *,
.members-text p + * {
  margin-top: 32px;
}

.members-text h1 + p,
.members-text h2 + p,
.members-text h3 + p,
.members-text h4 + p,
.members-text h5 + p,
.members-text h6 + p {
  margin-top: 8px;
}

.members-text a {
  color: #94a479;
}

@media (min-width: 768px) {
  .members {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 36px;
    -webkit-column-gap: 36px;
    -moz-column-gap: 36px;
    column-gap: 36px;
    grid-row-gap: 48px;
    row-gap: 48px;
  }

  .members-member + .members-member {
    margin-top: 0;
  }

  .members .picture {
    margin-bottom: 55px;
  }

  .members-position {
    margin-bottom: 40px;
  }
}

.videoCarousel {
  margin-top: 48px;
  overflow: hidden;
}

.videoCarousel .carousel,
.videoCarousel .splide,
.videoCarousel .splide__track {
  display: block;
  position: relative;
  width: 100%;
}

.videoCarousel .splide__track {
  overflow: visible;
  cursor: none;
}

.videoCarousel .splide__slide {
  pointer-events: none;
}

.videoCarousel .splide__pagination {
  position: relative;
  margin-top: 48px;
}

.videoCarousel .splide__pagination button {
  height: 4px;
  width: 4px;
  margin: 0 8px;
  background: #94a479;
}

.videoCarousel .splide__pagination button.is-active {
  background: #fff;
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  transform: scale(2);
  border-radius: 0;
  -webkit-clip-path: polygon(0 0, 0 100%, 100% 50%);
  clip-path: polygon(0 0, 0 100%, 100% 50%);
}

@media (min-width: 1024px) {
  .videoCarousel {
    margin-top: 96px;
  }

  .videoCarousel .carousel.is-dragging .videoSlide {
    margin: 0 6px;
  }
}

.videoSlide {
  display: block;
  position: relative;
  margin: 0;
  min-height: 232px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: all;
  -webkit-transition: margin 0.3s ease;
  -moz-transition: margin 0.3s ease;
  transition: margin 0.3s ease;
  overflow: hidden;
}

.videoSlide-video {
  position: relative;
  width: 100%;
  height: auto;
}

.videoSlide-poster {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.videoSlide-poster .picture {
  height: 100%;
}

.videoSlide-poster .picture img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.videoSlide-data {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -moz-box-pack: end;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  padding: 24px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}

.videoSlide-button,
.videoSlide-button:before {
  display: block;
  position: absolute;
  top: -webkit-calc(50% - 28px);
  top: -moz-calc(50% - 28px);
  top: calc(50% - 28px);
  left: -webkit-calc(50% - 28px);
  left: -moz-calc(50% - 28px);
  left: calc(50% - 28px);
  height: 56px;
  width: 56px;
}

.videoSlide-button:before {
  content: "";
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 100%, 0.3);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.videoSlide-button:after {
  content: "";
  display: block;
  position: absolute;
  top: -webkit-calc(50% - 4px);
  top: -moz-calc(50% - 4px);
  top: calc(50% - 4px);
  left: -webkit-calc(50% - 4px);
  left: -moz-calc(50% - 4px);
  left: calc(50% - 4px);
  width: 8px;
  height: 8px;
  background-color: #fff;
  -webkit-clip-path: polygon(0 0, 0 100%, 100% 50%);
  clip-path: polygon(0 0, 0 100%, 100% 50%);
}

.videoSlide-title {
  max-width: 358px;
  margin: 0 auto;
  text-align: center;
  text-transform: none;
  color: #fff;
}

@media (min-width: 768px) {
  .videoSlide {
    min-height: 320px;
  }
}

@media (min-width: 1024px) {
  .videoSlide-data {
    padding: 40px;
  }

  .videoSlide-button,
  .videoSlide-button:before {
    height: 80px;
    width: 80px;
    top: -webkit-calc(50% - 40px);
    top: -moz-calc(50% - 40px);
    top: calc(50% - 40px);
    left: -webkit-calc(50% - 40px);
    left: -moz-calc(50% - 40px);
    left: calc(50% - 40px);
  }
}

.jobLinks {
  color: #333;
}

.jobLinks,
.jobLinks-container {
  display: block;
  position: relative;
}

.jobLinks-container {
  margin-top: 32px;
  overflow: hidden;
}

.jobLinks-job {
  display: block;
  position: relative;
  -webkit-transform: perspective(400px) rotateX(0deg);
  -moz-transform: perspective(400px) rotateX(0deg);
  transform: perspective(400px) rotateX(0deg);
  -webkit-transform-origin: bottom;
  -moz-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.jobLinks-job + .jobLinks-job {
  margin-top: 24px;
}

.jobLinks-job:not(:last-child) {
  margin-bottom: 56px;
}

.jobLinks-job .link {
  margin-top: 8px;
}

.jobLinks-job-title {
  display: block;
  position: relative;
  margin: 0 auto 8px;
  text-transform: uppercase;
}

.jobLinks-job-description {
  max-width: 778px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .jobLinks {
    margin-top: 144px;
  }

  .jobLinks .sectionWrapper {
    text-align: center;
  }

  .jobLinks-container {
    margin-top: 88px;
  }

  .jobLinks-job {
    padding-bottom: 0;
    border-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    width: 83.33%;
  }

  .jobLinks-job + .jobLinks-job {
    margin-top: 96px;
  }

  .jobLinks-job:not(:last-child) {
    margin-bottom: 96px;
  }

  .jobLinks-job .link {
    margin-top: 40px;
  }

  .jobLinks-job-title {
    margin-bottom: 24px;
  }
}

.mainLayout.is-dark .jobLinks {
  color: #fff;
}

.mainLayout.is-dark .jobLinks .jobLinks-job-description {
  color: #999;
}

.mainLayout.is-dark .jobLinks .jobsLinks-job:after {
  background-color: #333;
}

.description {
  display: block;
  position: relative;
  text-align: left;
  color: #333;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.description > hr {
  width: 90%;
  margin-left: 5%;
}

.description-heading {
  display: block;
  position: relative;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
}

.description-heading + .description-content,
.description-heading + .description-text {
  margin-top: 40px;
}

.description-claim,
.description-title {
  display: block;
  position: relative;
}

.description-claim {
  /* padding-right: 25%; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.description-text {
  display: block;
  position: relative;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
}

.description-text h1,
.description-text h2,
.description-text h3,
.description-text h4,
.description-text h5,
.description-text h6 {
  color: #999;
}

.description-text h1 + *,
.description-text h2 + *,
.description-text h3 + *,
.description-text h4 + *,
.description-text h5 + *,
.description-text h6 + *,
.description-text p + * {
  margin-top: 40px;
}

.description-text h1 + p,
.description-text h2 + p,
.description-text h3 + p,
.description-text h4 + p,
.description-text h5 + p,
.description-text h6 + p {
  margin-top: 16px;
}

.description-text a {
  color: #94a479;
}

.description-content {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
}

.description .link {
  margin-top: 16px;
}

@media (min-width: 768px) {
  .description .link {
    margin-top: 32px;
  }
}

@media (min-width: 1024px) {
  .description .sectionWrapper {
    display: -moz-box;
    display: flex;
    -moz-box-align: start;
    align-items: center;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
    flex-wrap: nowrap;
    -moz-box-pack: justify;
    justify-content: space-between;
  }

  .description-heading {
    min-width: 33.33%;
    max-width: 33.33%;
  }

  .description-heading + .description-content,
  .description-heading + .description-text {
    margin-top: 0;
  }

  .description-text {
    min-width: 58.33%;
    max-width: 58.33%;
  }

  .description-content {
    min-width: 41.66%;
    max-width: 41.66%;
  }
}

.footer,
.mainLayout.is-dark .description {
  color: #fff;
}

.footer {
  padding: 48px 0 112px;
  background-color: #505f43;
  -webkit-transition: fill 0.3s ease;
  -moz-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.footer .sectionWrapper {
  height: 100%;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}

.footer .link-text {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #fff;
}

.footer-info {
  flex-wrap: wrap;
  padding-top: 40px;
  padding-bottom: 56px;
}

.footer-info,
.footer-info-contact,
.footer-info-headquarters {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}

.footer-info-contact,
.footer-info-headquarters {
  -moz-box-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.footer-info-contact .footer-block:not(:last-child),
.footer-info-headquarters .footer-block:not(:last-child) {
  margin-bottom: 24px;
}

.footer-info-contact {
  margin-bottom: 40px;
}

.footer-block {
  width: -webkit-calc(50% - 12px);
  width: -moz-calc(50% - 12px);
  width: calc(50% - 12px);
}

.footer-heading {
  opacity: 0.5;
}

.footer-nav {
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}

.footer-brand,
.footer-nav {
  display: -moz-box;
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  height: 100%;
}

.footer-brand {
  flex-wrap: wrap;
}

.footer-brand-menu,
.footer-brand-social {
  width: -webkit-calc(50% - 12px);
  width: -moz-calc(50% - 12px);
  width: calc(50% - 12px);
}

.footer-brand-legal {
  display: -moz-box;
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  margin-top: 3.5rem;
  align-self: flex-end;
}

.footer .link {
  display: block;
}

/* @media (hover: hover) {
  .footer .link:hover .link-text {
    color: #94a479;
  }

  .footer .link:hover .iconFarcoLogo path {
    fill: #94a479;
  }
} */

.footer .link-text {
  line-height: 32px;
}

.footer .link-text * {
  pointer-events: none;
}

.footer .iconFarcoLogo path {
  -webkit-transition: fill 0.3s ease;
  -moz-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.footer.is-contactPage {
  padding: 0;
  background-color: #000;
}

.footer.is-contactPage .sectionWrapper {
  padding-top: 100px;
  min-height: 100vh;
  min-height: -webkit-calc(var(--vh, 1vh) * 100);
  min-height: -moz-calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
}

.footer-logo .link {
  height: 19px;
}

@media (min-width: 1024px) {
  .footer {
    padding-top: 144px;
    padding-bottom: 48px;
  }

  .footer.banner-visible {
    padding-bottom: 112px;
  }

  .footer-main,
  .footer .sectionWrapper {
    -moz-box-pack: justify;
    justify-content: space-between;
  }

  .footer-main {
    display: -moz-box;
    display: flex;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .footer-claim {
    width: -webkit-calc(50% - 48px);
    width: -moz-calc(50% - 48px);
    width: calc(50% - 48px);
    max-width: 468px;
  }

  .footer-info {
    padding-top: 0;
    padding-bottom: 0;
    display: -moz-box;
    display: flex;
    -moz-box-pack: justify;
    justify-content: space-between;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
    width: 50%;
  }

  .footer-info-contact,
  .footer-info-headquarters {
    margin-bottom: 0;
    display: -moz-box;
    display: flex;
    -moz-box-pack: start;
    justify-content: flex-start;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    margin-right: 64px;
    width: -webkit-calc(50% - 76px);
    width: -moz-calc(50% - 76px);
    width: calc(50% - 76px);
  }

  .footer-block {
    width: 100%;
  }

  .footer-block + .footer-block {
    margin-top: 24px;
  }

  .footer-nav {
    display: -moz-box;
    display: flex;
    height: auto;
    -moz-box-pack: justify;
    justify-content: space-between;
    -moz-box-align: baseline;
    align-items: baseline;
    flex-wrap: wrap;
    margin-top: 144px;
  }

  .footer-brand {
    margin-bottom: 0;
    flex-wrap: nowrap;
    width: 100%;
  }

  .footer-brand .footer-heading {
    display: none;
  }

  .footer-brand-menu {
    width: -webkit-calc(50% - 48px);
    width: -moz-calc(50% - 48px);
    width: calc(50% - 48px);
  }

  .footer-brand-menu ul {
    display: -moz-box;
    display: flex;
    margin-right: 32px;
  }

  .footer-brand-menu li:not(:last-child) {
    margin-right: 32px;
  }

  .footer-brand-social {
    width: -webkit-calc(35% - 12px);
    width: -moz-calc(35% - 12px);
    width: calc(35% - 12px);
    margin-left: auto;
  }

  .footer-brand-social ul {
    display: -moz-box;
    display: flex;
    margin-right: 32px;
  }

  .footer-brand-social li:not(:last-child) {
    margin-right: 24px;
  }

  .footer-brand-legal {
    width: -webkit-calc(25% - 12px);
    width: -moz-calc(25% - 12px);
    width: calc(25% - 12px);
    margin-left: 24px;
    margin-top: 0;
  }

  .footer.is-contactPage {
    padding-top: 0;
    padding-bottom: 0;
  }

  .footer.is-contactPage .sectionWrapper {
    padding-top: 220px;
    padding-bottom: 48px;
    padding-top: 240px;
  }
}

.footer .footer-brand-legal .link.is-disabled .link-text,
.footer .footer-brand-menu .link.is-disabled .link-text {
  color: #fff;
}

.footer .footer-brand-legal .link .link-text,
.footer .footer-brand-menu .link .link-text {
  color: hsla(0, 0%, 100%, 0.5);
}

/* .footer .footer-brand-legal .link:hover .link-text,
.footer .footer-brand-menu .link:hover .link-text {
  color: #94a479;
} */

.footer .footer-brand-legal {
  -moz-box-align: center;
  align-items: center;
  display: -moz-box;
  display: flex;
}

/* @media (hover: hover) {
  .mainLayout.is-dark .footer .link:hover .link-text {
    color: #94a479;
  }

  .mainLayout.is-dark .footer .link:hover .iconFarcoLogo path {
    fill: #94a479;
  }
} */

.mainLayout.is-dark .footer .footer-brand-legal .link.is-disabled .link-text,
.mainLayout.is-dark .footer .footer-brand-menu .link.is-disabled .link-text,
.mainLayout.is-dark .footer .link .link-text {
  color: #fff;
}

.mainLayout.is-dark .footer .footer-brand-legal .link .link-text,
.mainLayout.is-dark .footer .footer-brand-menu .link .link-text {
  color: hsla(0, 0%, 100%, 0.5);
}

/* .mainLayout.is-dark .footer .footer-brand-legal .link:hover .link-text,
.mainLayout.is-dark .footer .footer-brand-menu .link:hover .link-text {
  color: #94a479;
} */

.home .description,
.home .relatedLinkContainer {
  margin-top: 80px;
}

@media (min-width: 1024px) {
  .home .description,
  .home .relatedLinkContainer {
    margin-top: 144px;
  }
}

.home .home-subtitle {
  color: #fff;
  margin-bottom: 32px;
}

@media (min-width: 768px) {
  .home .home-subtitle {
    margin-bottom: 80px;
  }
}

.home .caseStudyCover:first-child .caseStudyCover-fadeLayer {
  opacity: 1 !important;
}

.home .caseStudyContainer {
  margin-top: 0;
}

.home .caseStudyCover-text {
  color: #333;
}

.home .caseStudyCover .link-text {
  color: #505f43;
}

.mainLayout.is-dark .home .caseStudyCover-text {
  color: #fff;
}

@media screen and (max-width: 666px) {
  .home .caseStudyContainer .caseStudyCover:not(.is-full:first-child) {
    margin-top: 64px;
  }
}

.homeCarousel {
  margin-top: 80px;
  overflow: hidden;
}

.homeCarousel,
.homeCarousel-title {
  display: block;
  position: relative;
}

.homeCarousel-container {
  margin-top: 48px;
}

.homeCarousel-container,
.homeCarousel .carousel,
.homeCarousel .splide,
.homeCarousel .splide__track {
  display: block;
  position: relative;
  width: 100%;
}

.homeCarousel .splide__track {
  overflow: visible;
  cursor: none;
}

.homeCarousel .splide__slide {
  pointer-events: none;
}

@media (min-width: 768px) {
  .homeCarousel {
    margin-top: 144px;
  }

  .homeCarousel-container {
    margin-top: 88px;
  }
}

@media (min-width: 1024px) {
  .homeCarousel .carousel.is-dragging .homeSlide {
    padding: 0 6px;
  }
}

.homeHero {
  display: -moz-box;
  display: flex;
  -moz-box-align: start;
  align-items: flex-start;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  flex-wrap: nowrap;
  -moz-box-pack: justify;
  justify-content: space-between;
  position: relative;
  padding: 80px 0 62px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 0;
}

.homeHero .sectionWrapper {
  width: 100vw;
}

.homeHero-claimContainer {
  margin: 0 auto;
  text-align: left;
  color: wheat;
}

@media (min-width: 667px) {
  .homeHero-claimContainer {
    max-width: 100%;
    padding: 0 24px;
    text-align: center;
  }
}

.homeHero-claim {
  display: inline-block;
  position: relative;
  /* margin: 0 auto; */
  text-align: center;
  color: wheat;
  width: 100%;
  /* margin-top: 50px; */
  vertical-align: middle;
  height: 100%;
}

.homeHero-strengths {
  display: block;
  position: relative;
  width: 100%;
  z-index: -1;
}

@media (min-width: 1280px) {
  .homeHero-claim {
    max-width: 75%;
  }
}

@media (min-width: 667px) {
  .homeHero .sectionWrapper {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .homeHero {
    padding: 50px 0 50px;
    min-height: 0;
  }

  .homeHero-strengths {
    margin-top: 144px;
    z-index: 0;
  }

  .homeHero .strengths-word {
    margin: 0 24px;
  }
}

@media (min-width: 1280px) {
  .homeHero .sectionWrapper {
    text-align: left;
  }

  .homeHero-claim {
    width: 85%;
    max-width: 85%;
  }
}

@media (min-width: 1680px) {
  .homeHero {
    padding: 240px 0 180px;
  }

  .homeHero-claim {
    font-size: 40px;
    line-height: 64px;
  }

  .homeHero-strengths {
    margin-top: 180px;
  }
}

.homeSlide {
  display: block;
  position: relative;
  padding: 0;
  width: -webkit-calc(100vw - 48px);
  width: -moz-calc(100vw - 48px);
  width: calc(100vw - 48px);
  -webkit-transition: padding 0.3s ease;
  -moz-transition: padding 0.3s ease;
  transition: padding 0.3s ease;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.homeSlide.is-thin {
  width: -webkit-calc(75vw - 48px);
  width: -moz-calc(75vw - 48px);
  width: calc(75vw - 48px);
}

@media (min-width: 667px) {
  .homeSlide {
    width: -webkit-calc(75vw - 48px);
    width: -moz-calc(75vw - 48px);
    width: calc(75vw - 48px);
  }

  .homeSlide.is-thin {
    width: -webkit-calc(50vw - 48px);
    width: -moz-calc(50vw - 48px);
    width: calc(50vw - 48px);
  }
}

@media (min-width: 768px) {
  .homeSlide {
    width: -webkit-calc(75vw - 96px);
    width: -moz-calc(75vw - 96px);
    width: calc(75vw - 96px);
  }

  .homeSlide.is-thin {
    width: -webkit-calc(50vw - 96px);
    width: -moz-calc(50vw - 96px);
    width: calc(50vw - 96px);
  }
}

@media (min-width: 1024px) {
  .homeSlide {
    width: -webkit-calc(50vw - 128px);
    width: -moz-calc(50vw - 128px);
    width: calc(50vw - 128px);
  }

  .homeSlide.is-thin {
    width: -webkit-calc(33.33vw - 128px);
    width: -moz-calc(33.33vw - 128px);
    width: calc(33.33vw - 128px);
  }
}

@media (min-width: 1280px) {
  .homeSlide {
    width: -webkit-calc(50vw - 240px);
    width: -moz-calc(50vw - 240px);
    width: calc(50vw - 240px);
    max-width: 952px;
  }

  .homeSlide.is-thin {
    width: -webkit-calc(33.33vw - 240px);
    width: -moz-calc(33.33vw - 240px);
    width: calc(33.33vw - 240px);
    max-width: 634px;
  }
}

.homeSlideItem {
  display: block;
  position: relative;
}

.homeSlideItem + .homeSlideItem {
  margin-top: 16px;
}

.homeSlideItem-image {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}

.homeSlideItem-image .icon {
  display: block;
  position: absolute;
  top: 12px;
  right: 12px;
  width: auto;
  max-width: 24px;
  height: auto;
  max-height: 24px;
  pointer-events: none;
}

.homeSlideItem .picture img {
  width: 100%;
}

.homeSlideItem-link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: all;
  cursor: none;
}

.homeSlideItem-info {
  display: block;
  position: relative;
  margin-top: 32px;
}

.homeSlideItem-description {
  display: block;
  position: relative;
  color: #333;
  width: 100%;
}

.homeSlideItem-category {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-box-pack: start;
  justify-content: flex-start;
  position: relative;
  margin-top: 8px;
  color: #999;
}

.homeSlideItem-categoryText {
  display: block;
  position: relative;
}

.homeSlideItem-categoryText + .homeSlideItem-categoryText {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-box-pack: start;
  justify-content: flex-start;
}

.homeSlideItem-categoryText + .homeSlideItem-categoryText:before {
  content: "";
  display: block;
  position: relative;
  margin: 0 16px;
  width: 52px;
  height: 1px;
  background-color: #c4c4c4;
}

@media (min-width: 768px) {
  .homeSlideItem + .homeSlideItem {
    margin-top: 32px;
  }

  .homeSlideItem-image .icon {
    top: 16px;
    right: 16px;
  }

  .homeSlideItem-description {
    max-width: 83.33%;
  }

  .homeSlideItem-category {
    margin-top: 16px;
  }
}

@media (min-width: 1280px) {
  .homeSlideItem + .homeSlideItem {
    margin-top: 64px;
  }
}

.strengths {
  display: none;
  position: relative;
  -moz-box-pack: end;
  justify-content: flex-end;
  width: 100%;
}

/* .strengths.is-mobile,
.strengths:not(.has-hover) {
  display: -moz-box;
  display: flex;
  pointer-events: none;
} */

.strengths-words,
.strengths.is-reverse {
  -moz-box-pack: start;
  justify-content: flex-start;
}

.strengths-words {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  pointer-events: none;
  will-change: transform;
  z-index: 0;
}

@media (hover: hover) {
  .strengths-words:hover {
    z-index: 1;
  }
}

.strengths-word {
  display: block;
  position: relative;
  margin: 0 8px;
  color: #eee;
  white-space: nowrap;
  pointer-events: all;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
  z-index: 0;
}

.strengths-word.is-focus {
  color: #333;
}

/* @media(hover: hover) {
    .strengths.has-hover .strengths-word:hover {
        color:#333;
        z-index: 1
    }

    .strengths.has-hover .strengths-word:hover .persperctiveImage {
        opacity: 1;
        -webkit-transition: opacity 1s;
        -moz-transition: opacity 1s;
        transition: opacity 1s
    }

    .strengths.has-hover .strengths-word:hover .persperctiveImage .picture {
        -webkit-transform: perspective(1800px) rotateY(0deg);
        -moz-transform: perspective(1800px) rotateY(0deg);
        transform: perspective(1800px) rotateY(0deg)
    }
} */

.strengths-word .persperctiveImage {
  z-index: -1;
}

/* @media(hover: none) {
    .strengths-word {
        pointer-events:none
    }
} */

@media (min-width: 768px) {
  .strengths {
    display: -moz-box;
    display: flex;
  }

  .strengths.is-mobile {
    display: none;
  }

  .strengths .strengths-word {
    margin: 0 24px;
  }
}

.metrics {
  padding-top: 32px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #333;
}

@media (min-width: 768px) {
  .metrics {
    padding-top: 56px;
  }
}

.metrics .metrics-title {
  color: #333;
  text-align: left;
  white-space: nowrap;
  margin-bottom: 16px;
  text-transform: inherit;
}

@media (min-width: 1280px) {
  .metrics .metrics-title {
    margin-top: 3px;
    margin-bottom: 0;
  }
}

@media (min-width: 375px) {
  .metrics {
    margin: 0 auto;
  }
}

@media (min-width: 667px) {
  .metrics {
    max-width: 75%;
  }
}

@media (min-width: 768px) {
  .metrics > div {
    border-top: 1px solid #e1e1e1;
  }
}

.metrics .metrics-container {
  padding: 24px 0 0;
  display: -moz-box;
  display: flex;
  max-width: 100%;
  -moz-box-pack: justify;
  justify-content: space-between;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .metrics .metrics-container {
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
  }
}

.metrics .metrics__item-container {
  -moz-box-flex: 1;
  flex-grow: 1;
}

.metrics .metrics__item-container,
.metrics .metrics__item-container .metric-item {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}

.metrics .metrics__item-container .metric-item {
  text-align: left;
  text-transform: inherit;
  -moz-box-align: start;
  align-items: flex-start;
  opacity: 0;
  position: absolute;
}

@media screen and (min-width: 667px) {
  .metrics .metrics__item-container .metric-item {
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
    -moz-box-align: end;
    align-items: flex-end;
  }
}

@-webkit-keyframes opacityTranslateY {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  10% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  90% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
}

@-moz-keyframes opacityTranslateY {
  0% {
    opacity: 0;
    -moz-transform: translateX(30px);
    transform: translateX(30px);
  }

  10% {
    opacity: 1;
    -moz-transform: translateX(0);
    transform: translateX(0);
  }

  90% {
    opacity: 1;
    -moz-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    opacity: 0;
    -moz-transform: translateX(-30px);
    transform: translateX(-30px);
  }
}

@keyframes opacityTranslateY {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    transform: translateX(30px);
  }

  10% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }

  90% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    transform: translateX(-30px);
  }
}

.metrics .metrics__item-container .metric-item--active {
  -webkit-animation-name: opacityTranslateY;
  -moz-animation-name: opacityTranslateY;
  animation-name: opacityTranslateY;
  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
  z-index: 2;
}

.metrics .metrics__item-container .metric-item--no-slider {
  opacity: 1;
  position: relative;
  z-index: 2;
}

@media (min-width: 1024px) {
  .metrics .metrics__item-container .metric-item {
    -moz-box-pack: end;
    justify-content: flex-end;
    padding-left: 80px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.metrics .metrics__item-container .metric-item h2 > span {
  margin-right: 8px;
  display: block;
}

@media screen and (min-width: 768px) {
  .metrics .metrics__item-container .metric-item h2 > span {
    display: -moz-inline-box;
    display: inline-flex;
  }
}

.metrics .metrics__item-container .metric-item a {
  white-space: nowrap;
  margin-top: 24px;
}

@media screen and (min-width: 768px) {
  .metrics .metrics__item-container .metric-item a {
    margin-top: 4px;
  }
}

.metrics .metrics__item-container .metric-item a .link-text {
  color: #94a479;
}

/* .metrics .metrics__item-container .metric-item a .link-text:hover,.metrics .metrics__item-container .metric-item a:hover {
    color: #505f43
} */

.layoutSection {
  display: block;
  position: relative;
}

.layoutSection + .layoutSection.is-dark,
.layoutSection.is-dark + .layoutSection {
  padding-top: 40px;
}

.layoutSection + .layoutSection.is-collapsed.is-dark {
  margin-top: 40px;
  padding-top: 0;
}

.mainLayout {
  display: block;
  position: relative;
  width: 100%;
  height: 100vh;
  height: -webkit-calc(var(--vh, 1vh) * 100);
  height: -moz-calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  background-color: #fff;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  scroll-behavior: smooth;
}

.mainLayout.is-dark {
  background-color: #000;
}

.mainLayout.is-lock {
  overflow-y: hidden;
}

.mainLayout-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}

.mainLayout .footer {
  margin-top: 80px;
}

.mainLayout .footer.is-contactPage {
  margin-top: 0;
}

@media (min-width: 768px) {
  .mainLayout .footer {
    margin-top: 144px;
  }

  .mainLayout .footer.is-contactPage {
    margin-top: 0;
  }
}

.pageHeader {
  display: block;
  position: relative;
  padding-top: 168px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.pageHeader.is-centered .sectionWrapper {
  text-align: center;
}

.pageHeader.is-centered .pageHeader-claim,
.pageHeader.is-centered .pageHeader-description,
.pageHeader.is-centered .pageHeader-heading,
.pageHeader.is-centered .pageHeader-subtitle {
  padding-right: 0;
  text-align: center;
}

.pageHeader.is-centered .pageHeader-heading {
  -moz-box-pack: center;
  justify-content: center;
}

.pageHeader + * {
  margin-top: 80px;
}

.pageHeader
  + .caseStudyContainer
  .caseStudyCover:first-child
  .caseStudyCover-fadeLayer,
.pageHeader
  + .testimony
  + .caseStudyContainer
  .caseStudyCover:first-child
  .caseStudyCover-fadeLayer {
  opacity: 1 !important;
}

.pageHeader-heading {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;
  -moz-box-pack: start;
  justify-content: flex-start;
  position: relative;
  margin: 0 auto;
  color: #999;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.pageHeader-heading + .pageHeader-claim {
  margin-top: 8px;
}

.pageHeader-claim {
  margin: 0 auto;
}

.pageHeader-claim,
.pageHeader-subtitle {
  display: block;
  position: relative;
  color: #333;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.pageHeader-subtitle {
  margin: 32px auto 0;
}

.pageHeader-description {
  margin: 16px auto 0;
}

.pageHeader-description,
.pageHeader-text {
  display: block;
  position: relative;
  color: #333;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.pageHeader-text {
  margin-top: 16px;
  text-align: left;
}

.pageHeader-text h1,
.pageHeader-text h2,
.pageHeader-text h3,
.pageHeader-text h4,
.pageHeader-text h5,
.pageHeader-text h6 {
  color: #999;
}

.pageHeader-text h1 + *,
.pageHeader-text h2 + *,
.pageHeader-text h3 + *,
.pageHeader-text h4 + *,
.pageHeader-text h5 + *,
.pageHeader-text h6 + *,
.pageHeader-text p + * {
  margin-top: 32px;
}

.pageHeader-text h1 + p,
.pageHeader-text h2 + p,
.pageHeader-text h3 + p,
.pageHeader-text h4 + p,
.pageHeader-text h5 + p,
.pageHeader-text h6 + p {
  margin-top: 8px;
}

.pageHeader-text a {
  color: #94a479;
}

@media (min-width: 768px) {
  .pageHeader {
    padding-top: 200px;
  }

  .pageHeader + * {
    margin-top: 144px;
  }

  .pageHeader-heading + .pageHeader-claim {
    margin-top: 16px;
  }

  .pageHeader-description {
    margin-top: 72px;
  }

  .pageHeader-text {
    margin-top: 32px;
  }
}

@media (min-width: 1280px) {
  .pageHeader .sectionWrapper {
    text-align: center;
  }

  .pageHeader-claim,
  .pageHeader-heading {
    width: 83.33%;
  }

  .pageHeader-claim:not(.is-centered),
  .pageHeader-heading:not(.is-centered) {
    text-align: left;
  }

  .pageHeader-description,
  .pageHeader-text {
    margin-left: 8.33%;
  }

  .pageHeader-description:not(.is-centered),
  .pageHeader-text:not(.is-centered) {
    text-align: left;
  }
}

@media (min-width: 1440px) {
  .pageHeader + .testimony .testimony-info,
  .pageHeader + .testimony .testimony-text {
    padding-right: 16.66%;
  }

  .pageHeader-claim,
  .pageHeader-heading {
    padding-right: 8.33%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .pageHeader-description,
  .pageHeader-text {
    margin-right: 20%;
  }
}

@media (min-width: 1680px) {
  .pageHeader {
    padding-top: 240px;
  }
}

.mainLayout.is-dark .pageHeader-claim,
.mainLayout.is-dark .pageHeader-description,
.mainLayout.is-dark .pageHeader-heading,
.mainLayout.is-dark .pageHeader-subtitle,
.mainLayout.is-dark .pageHeader-text {
  color: #fff;
}

.sectionWrapper {
  display: block;
  position: relative;
  margin: 0 auto;
  padding: 0 24px;
  text-align: left;
  max-width: 1904px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .sectionWrapper {
    padding: 0 48px;
  }
}

@media (min-width: 1024px) {
  .sectionWrapper {
    padding: 0 64px;
  }
}

@media (min-width: 1280px) {
  .sectionWrapper {
    padding: 0 120px;
  }
}

.transitionLayout {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

.transitionLayout.is-fadeIn .transitionLayout-wrapper {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s ease 0.5s;
  transition: opacity 0.5s, -webkit-transform 0.5s ease 0.5s;
  -moz-transition: opacity 0.5s, transform 0.5s ease 0.5s,
    -moz-transform 0.5s ease 0.5s;
  transition: opacity 0.5s, transform 0.5s ease 0.5s;
  transition: opacity 0.5s, transform 0.5s ease 0.5s,
    -webkit-transform 0.5s ease 0.5s, -moz-transform 0.5s ease 0.5s;
}

.transitionLayout.is-fadeOut .transitionLayout-wrapper {
  opacity: 0;
  -webkit-transform: scale(0.96);
  -moz-transform: scale(0.96);
  transform: scale(0.96);
  -webkit-transition: opacity 0.2s 0.5s, -webkit-transform 0.5s ease;
  transition: opacity 0.2s 0.5s, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.2s 0.5s, transform 0.5s ease,
    -moz-transform 0.5s ease;
  transition: opacity 0.2s 0.5s, transform 0.5s ease;
  transition: opacity 0.2s 0.5s, transform 0.5s ease,
    -webkit-transform 0.5s ease, -moz-transform 0.5s ease;
}

.transitionLayout-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  transform-origin: center;
}
/* 
.link {
    display: -moz-inline-box;
    display: inline-flex;
    -moz-box-align: center;
    align-items: center;
    position: relative;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
    flex-wrap: nowrap
}

.link.is-disabled {
    pointer-events: none
}

.link.is-disabled .link-text {
    color: #333
} */

/* @media(hover: hover) {
    .link:hover .link-text {
        color:#94a479
    }

    .link:hover .iconArrow path {
        fill: #94a479
    }
} */
/* 
.link .iconArrow {
    display: block;
    position: relative;
    margin-right: 8px;
    width: 16px;
    height: 16px
} */

/* .link .iconArrow path {
    fill: #505f43;
    -webkit-transition: fill .3s ease;
    -moz-transition: fill .3s ease;
    transition: fill .3s ease
} */

.link-text {
  display: block;
  position: relative;
  /* color: #505f43;
    -webkit-transition: color .3s ease;
    -moz-transition: color .3s ease;
    transition: color .3s ease */
}

/* @media(hover: hover) {
    .mainLayout.is-dark .link:hover .link-text {
        color:#505f43
    }

    .mainLayout.is-dark .link:hover .iconArrow path {
        fill: #505f43
    }
} */

.mainLayout.is-dark .link .iconArrow path {
  fill: #94a479;
}

.mainLayout.is-dark .link-text {
  color: #94a479;
}

.mouseSmall {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: #000;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: width 0.3s ease, height 0.3s ease, opacity 0.3s ease;
  -moz-transition: width 0.3s ease, height 0.3s ease, opacity 0.3s ease;
  transition: width 0.3s ease, height 0.3s ease, opacity 0.3s ease;
  z-index: 20;
}

/* .mouseSmall.is-hover,
.mouseSmall.is-hoverGreen {
  width: 8px;
  height: 8px;
  background-color: #000;
  opacity: 1;
} */
/* 
.mouseSmall.is-hoverGreen {
  background-color: #94a479;
}

@media (hover: hover) {
  .mouseSmall {
    display: block;
  }
} */

/* .mousePointer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 96px;
  height: 96px;
  pointer-events: none;
  z-index: 20;
}

@media (hover: hover) {
  .mousePointer {
    display: block;
  }
} */

/* .mousePointer.is-drag .mousePointer-pointer,
.mousePointer.is-link .mousePointer-pointer,
.mousePointer.is-linkExternal .mousePointer-pointer,
.mousePointer.is-video .mousePointer-pointer,
.mousePointer.is-view .mousePointer-pointer {
  width: 96px;
  height: 96px;
  background-color: #94a479;
  opacity: 1;
  -webkit-transition: width 0.3s ease, height 0.3s ease,
    background-color 0.3s ease, border-radius 0.3s ease, opacity 0.3s ease;
  -moz-transition: width 0.3s ease, height 0.3s ease, background-color 0.3s ease,
    border-radius 0.3s ease, opacity 0.3s ease;
  transition: width 0.3s ease, height 0.3s ease, background-color 0.3s ease,
    border-radius 0.3s ease, opacity 0.3s ease;
}

.mousePointer.is-drag .mousePointer-text.is-drag,
.mousePointer.is-video .mousePointer-text.is-video,
.mousePointer.is-view .mousePointer-text.is-link {
  opacity: 1;
}

.mousePointer.is-video .mousePointer-text.is-video ~ .iconArrow,
.mousePointer.is-view .mousePointer-text.is-link ~ .iconArrow {
  opacity: 0;
}

.mousePointer.is-link .iconArrow,
.mousePointer.is-linkExternal .iconArrow {
  opacity: 1;
}

.mousePointer.is-linkExternal .iconArrow {
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

.mousePointer-pointer {
  position: relative;
  width: 8px;
  height: 8px;
  background-color: #94a479;
  border-radius: 100%;
  -webkit-transition: width 0.3s ease, height 0.3s ease,
    background-color 0.3s ease, border-radius 0.3s ease, opacity 0.3s ease 0.3s;
  -moz-transition: width 0.3s ease, height 0.3s ease, background-color 0.3s ease,
    border-radius 0.3s ease, opacity 0.3s ease 0.3s;
  transition: width 0.3s ease, height 0.3s ease, background-color 0.3s ease,
    border-radius 0.3s ease, opacity 0.3s ease 0.3s;
}

.mousePointer-pointer,
.mousePointer-text {
  display: block;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.mousePointer-text {
  position: absolute;
  color: #fff;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.mousePointer-text:after,
.mousePointer-text:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -14px;
  width: 0;
  height: 0;
  border-color: transparent #fff transparent transparent;
  border-style: solid;
  border-width: 3px 6px 3px 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -moz-transition: transform 0.3s ease, -moz-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease,
    -moz-transform 0.3s ease;
}

.mousePointer-text:after {
  right: -14px;
  left: auto;
  border-width: 3px 0 3px 6px;
  border-color: transparent transparent transparent #fff;
}

.mousePointer .iconArrow {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(0deg);
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
  -webkit-transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
  -moz-transition: opacity 0.3s ease, transform 0.3s ease,
    -moz-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease,
    -webkit-transform 0.3s ease, -moz-transform 0.3s ease;
}

.mousePointer .iconArrow path {
  fill: #fff;
} */

.mainLayout.is-dark .mouseSmall.is-hover {
  background-color: #fff;
}

.mainLayout.is-dragging .mousePointer-text:before {
  -webkit-transform: translateX(-4px) translateY(-50%);
  -moz-transform: translateX(-4px) translateY(-50%);
  transform: translateX(-4px) translateY(-50%);
}

.mainLayout.is-dragging .mousePointer-text:after {
  -webkit-transform: translateX(4px) translateY(-50%);
  -moz-transform: translateX(4px) translateY(-50%);
  transform: translateX(4px) translateY(-50%);
}

.mediaBlock {
  display: block;
  position: relative;
  width: 100%;
  height: 82vw;
  overflow: hidden;
}

.mediaBlock + .description {
  margin-top: 80px;
}

.mediaBlock .picture,
.mediaBlock .video,
.mediaBlock img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mediaBlock .picture {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transform-origin: top center;
  -moz-transform-origin: top center;
  transform-origin: top center;
}

@media (min-width: 768px) {
  .mediaBlock {
    height: auto;
  }

  .mediaBlock + .description {
    margin-top: 144px;
  }

  .mediaBlock .picture,
  .mediaBlock .video,
  .mediaBlock img {
    width: 100%;
    height: auto;
  }
}

.cityClock {
  display: block;
  position: relative;
  text-align: left;
}

.cityClock.is-collapsed {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  text-transform: uppercase;
}

.cityClock.is-collapsed .cityClock-city {
  margin-right: 8px;
}

.cityClock-city,
.cityClock-time {
  display: block;
  position: relative;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #999;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.menuDesktop {
  display: block;
  position: relative;
  padding: 48px 0;
  width: 100%;
  height: 100vh;
  height: -webkit-calc(var(--vh, 1vh) * 100);
  height: -moz-calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  background-color: #000;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0;
}

.menuDesktop-image,
.menuDesktop-image .picture,
.menuDesktop-images {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.menuDesktop-images {
  overflow: hidden;
}

.menuDesktop-images:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.menuDesktop-image {
  opacity: 0;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  transform-origin: center;
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s, -webkit-transform 0.4s;
  -moz-transition: opacity 0.4s, transform 0.4s, -moz-transform 0.4s;
  transition: opacity 0.4s, transform 0.4s;
  transition: opacity 0.4s, transform 0.4s, -webkit-transform 0.4s,
    -moz-transform 0.4s;
}

.menuDesktop-image.is-active {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 3s, -webkit-transform 6s ease-out;
  transition: opacity 3s, -webkit-transform 6s ease-out;
  -moz-transition: opacity 3s, transform 6s ease-out, -moz-transform 6s ease-out;
  transition: opacity 3s, transform 6s ease-out;
  transition: opacity 3s, transform 6s ease-out, -webkit-transform 6s ease-out,
    -moz-transform 6s ease-out;
}

.menuDesktop-image .picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menuDesktop-logo {
  display: block;
  position: relative;
  text-align: center;
}

.menuDesktop-logo .link {
  margin: 0 auto;
}

.menuDesktop-claim {
  display: block;
  position: relative;
  margin-top: 54px;
  width: 41.66%;
  max-width: 462px;
  color: #fff;
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.menuDesktop .relatedLinkContainer {
  margin-top: 72px;
}

.menuDesktop
  .relatedLinkContainer
  .relatedLink
  + .relatedLink
  .relatedLink-wrapper {
  padding-top: 32px;
}

.menuDesktop
  .relatedLinkContainer
  .relatedLink:last-child
  .relatedLink-wrapper {
  padding-bottom: 32px;
}

.menuDesktop
  .relatedLinkContainer
  .relatedLink:last-child
  .relatedLink-wrapper:before {
  display: block;
}

.menuDesktop .relatedLink {
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.menuDesktop .relatedLink .relatedLink-wrapper {
  padding-bottom: 32px;
}

.menuDesktop .relatedLink .relatedLink-wrapper:before {
  background-color: hsla(0, 0%, 100%, 0.2);
}

.menuDesktop .relatedLink .relatedLink-titleCaption {
  color: hsla(0, 0%, 100%, 0.2);
}

.menuDesktop .relatedLink-titleText {
  font-size: 4rem;
  line-height: 5rem;
  color: #fff;
}

.menuDesktop-close {
  display: block;
  position: absolute;
  top: 52px;
  right: 64px;
  width: 16px;
  height: 16px;
}

/* @media (hover: hover) {
  .menuDesktop-close:hover:after,
  .menuDesktop-close:hover:before {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
    -moz-transform: translateX(-50%) translateY(-50%) rotate(0deg);
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }
} */

.menuDesktop-close:after,
.menuDesktop-close:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  background-color: #fff;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -moz-transition: transform 0.3s ease, -moz-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease,
    -moz-transform 0.3s ease;
}

.menuDesktop-close:after {
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.menuMobile {
  display: block;
  position: relative;
  padding: 32px 24px 48px;
  width: 100%;
  height: 100vh;
  height: -webkit-calc(var(--vh, 1vh) * 100);
  height: -moz-calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  text-align: left;
  background-color: #000;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: auto;
  opacity: 0;
}

.menuMobile .iconFarcoLogo {
  width: 72px;
}

.menuMobile .iconFarcoLogo path {
  fill: #fff;
}

.menuMobile-navbar {
  -moz-box-pack: justify;
  justify-content: space-between;
  padding-top: 112px;
  min-height: -webkit-calc(100% - 20px);
  min-height: -moz-calc(100% - 20px);
  min-height: calc(100% - 20px);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.menuMobile-navbar,
.menuMobile-navbar-links {
  display: -moz-box;
  display: flex;
  -moz-box-align: start;
  align-items: flex-start;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
}

.menuMobile-navbar-links {
  -moz-box-pack: start;
  justify-content: flex-start;
}

.menuMobile-heading {
  display: block;
  position: relative;
  color: #999;
}

.menuMobile-heading + .animated-item {
  margin-top: 32px;
}

.menuMobile .animated-item {
  display: block;
  position: relative;
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.menuMobile .animated-item + .animated-item {
  margin-top: 24px;
}

.menuMobile .animated-item + .menuMobile-heading {
  margin-top: 48px;
}

.menuMobile .link-text {
  color: #fff;
}

.menuMobile-navbar-footer {
  display: -moz-box;
  display: flex;
  -moz-box-align: start;
  align-items: flex-start;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-box-pack: start;
  justify-content: flex-start;
  margin-top: 112px;
}

.menuMobile-navbar-footer .cityClock + .cityClock {
  margin-left: 48px;
}

@media (min-width: 768px) {
  .menuMobile {
    padding: 32px 48px 48px;
  }
}

.navigationCollapsed {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: hsla(0, 0%, 100%, 0.9);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -moz-transition: transform 0.3s ease, -moz-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease,
    -moz-transform 0.3s ease;
  z-index: 10;
}

.navigationCollapsed.is-contact {
  background-color: rgba(80, 95, 67, 0.9);
}

.navigationCollapsed.is-contact
  .navigationCollapsed-navbar
  .iconFarcoLogo
  path {
  fill: #fff;
}

.navigationCollapsed.is-contact .cityClock-city,
.navigationCollapsed.is-contact .cityClock-time {
  color: #fff;
}

.navigationCollapsed.is-hidden {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  transform: translateY(-100%);
}

.navigationCollapsed.is-home .navigationCollapsed-navbar .link:first-child {
  pointer-events: none;
}

.navigationCollapsed.is-home .navigationCollapsed-navbarLinks .link-text {
  color: #333;
}

.navigationCollapsed.is-home .navigationCollapsed-navbarLinks .link-text:hover {
  color: #505f43;
}

.navigationCollapsed-navbar {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-box-pack: justify;
  justify-content: space-between;
  position: relative;
  margin: 32px 0;
  height: 20px;
}

.navigationCollapsed-navbar .iconFarcoLogo {
  width: 72px;
}

.navigationCollapsed-navbar .iconFarcoLogo path {
  fill: #505f43;
  -webkit-transition: fill 0.3s ease;
  -moz-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

/* @media (hover: hover) {
  .navigationCollapsed .link:hover .iconFarcoLogo path {
    fill: #94a479;
  }
} */

.navigationCollapsed-navbarLinks {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-box-pack: start;
  justify-content: flex-start;
}

.navigationCollapsed-navbarLinks .link + .link {
  margin-left: 40px;
}

.navigationCollapsed-navbarLinks .link-text {
  color: #999;
}

.navigationCollapsed-clocks {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: start;
  align-items: flex-start;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-box-pack: end;
  justify-content: flex-end;
}

.navigationCollapsed .cityClock + .cityClock {
  margin-left: 56px;
}

.navigationCollapsed .cityClock-city,
.navigationCollapsed .cityClock-time {
  color: #333;
  font-size: 1rem;
}

@media (min-width: 1024px) {
  .navigationCollapsed.is-home .navigationCollapsed-navbar .link:first-child {
    pointer-events: all;
  }

  .navigationCollapsed.is-home .navigationCollapsed-navbarLinks + .link {
    pointer-events: none;
  }

  .navigationCollapsed-navbar {
    margin: 24px 0;
  }
}

@media (min-width: 1280px) {
  .navigationCollapsed .sectionWrapper {
    padding: 0 64px;
    max-width: 100%;
  }
}

.mainLayout.is-dark .navigationCollapsed {
  background-color: rgba(0, 0, 0, 0.9);
}
/* 
@media (hover: hover) {
  .mainLayout.is-dark .navigationCollapsed .link:hover .iconFarcoLogo path {
    fill: #94a479;
  }

  .mainLayout.is-dark .navigationCollapsed .link:hover .link-text {
    color: #94a479;
  }
} */

.mainLayout.is-dark .navigationCollapsed .iconFarcoLogo path {
  fill: #fff;
}

.mainLayout.is-dark .navigationCollapsed .cityClock-city,
.mainLayout.is-dark .navigationCollapsed .cityClock-time,
.mainLayout.is-dark .navigationCollapsed .link-text {
  color: #999;
}

.mainLayout.is-dark .navigationCollapsed .link.is-disabled .link-text {
  color: #fff;
}

.navigationDesktop {
  display: block;
  position: relative;
  z-index: 10;
}

.navigationDesktop + .about,
.navigationDesktop + .banner + .about,
.navigationDesktop + .banner + .caseStudy,
.navigationDesktop + .banner + .culture,
.navigationDesktop + .banner + .layoutSection,
.navigationDesktop + .caseStudy,
.navigationDesktop + .culture,
.navigationDesktop + .layoutSection {
  margin-top: -68px;
}

.navigationDesktop > .sectionWrapper {
  display: -moz-box;
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
}

.navigationDesktop.is-home .link-text {
  color: wheat;
}

.navigationDesktop.is-home .link-text:hover {
  color: #505f43;
}

.navigationDesktop.is-home .menuDesktop-logo,
.navigationDesktop.is-home > .link:first-child {
  pointer-events: none;
}

.navigationDesktop.is-open .navigationDesktop-menuWrapper {
  pointer-events: all;
}

.navigationDesktop .sectionWrapper {
  margin-top: 48px;
  max-width: 100%;
}

.navigationDesktop .menuDesktop .sectionWrapper {
  margin-top: 0;
  max-width: 1904px;
}

/* @media (hover: hover) {
  .navigationDesktop .link:hover .iconFarcoLogo path {
    fill: #94a479;
  }
} */

.navigationDesktop .iconFarcoLogo {
  width: 72px;
}

.navigationDesktop .iconFarcoLogo path {
  fill: #505f43;
  -webkit-transition: fill 0.3s ease;
  -moz-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navigationDesktop .menuDesktop .iconFarcoLogo {
  width: 72px;
}

.navigationDesktop .menuDesktop .iconFarcoLogo path {
  fill: #fff;
}

.navigationDesktop-navbar {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: flex-end;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-box-pack: start;
  justify-content: flex-start;
  height: 20px;
}

.navigationDesktop-navbar .link + .link {
  margin-left: 40px;
}

.navigationDesktop-navbar .link-text.is-disabled {
  color: #000;
}

.navigationDesktop-clocks {
  display: block;
  position: absolute;
  top: 0;
  right: 64px;
}

.navigationDesktop-clocks .cityClock {
  text-align: right;
}

.navigationDesktop-clocks .cityClock + .cityClock {
  margin-top: 40px;
}

.navigationDesktop-clocks .cityClock-city,
.navigationDesktop-clocks .cityClock-time {
  color: #333;
  font-size: 1rem;
}

.navigationDesktop-menu {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: -webkit-calc(var(--vh, 1vh) * 100);
  height: -moz-calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  pointer-events: none;
}

.navigationDesktop-menuWrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

@media (min-width: 1280px) {
  .navigationDesktop .sectionWrapper {
    padding: 0 64px;
  }
}

/* @media (hover: hover) {
  .mainLayout.is-dark .navigationDesktop .link:hover .iconFarcoLogo path,
  .navigationDesktop.is-contact .link:hover .iconFarcoLogo path {
    fill: #94a479;
  }

  .mainLayout.is-dark .navigationDesktop .link:hover .link-text,
  .navigationDesktop.is-contact .link:hover .link-text {
    color: #94a479;
  }
} */

.mainLayout.is-dark .navigationDesktop .iconFarcoLogo path,
.navigationDesktop.is-contact .iconFarcoLogo path {
  fill: #fff;
}

.mainLayout.is-dark .navigationDesktop .cityClock-city,
.mainLayout.is-dark .navigationDesktop .cityClock-time,
.mainLayout.is-dark .navigationDesktop .link-text,
.navigationDesktop.is-contact .cityClock-city,
.navigationDesktop.is-contact .cityClock-time,
.navigationDesktop.is-contact .link-text {
  color: #999;
}

.mainLayout.is-dark .navigationDesktop .link.is-disabled .link-text,
.navigationDesktop.is-contact .link.is-disabled .link-text {
  color: #fff;
}

.navigationMobile {
  display: block;
  position: relative;
  z-index: 10;
}

.navigationMobile + .about,
.navigationMobile + .banner + .about,
.navigationMobile + .banner + .caseStudy,
.navigationMobile + .banner + .culture,
.navigationMobile + .banner + .layoutSection,
.navigationMobile + .caseStudy,
.navigationMobile + .culture,
.navigationMobile + .layoutSection {
  margin-top: -52px;
}

.navigationMobile.is-home .navigationMobile-navbar .link:first-child {
  pointer-events: none;
}

.navigationMobile.is-open .navigationMobile-menuWrapper {
  pointer-events: all;
}

.navigationMobile.is-open .navigationMobile-menuButton {
  background-color: #94a479;
}

.navigationMobile.is-open .navigationMobile-menuButton:before {
  -webkit-animation: openMenuTop;
  -moz-animation: openMenuTop;
  animation: openMenuTop;
  -webkit-animation-duration: 0.6s;
  -moz-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.navigationMobile.is-open .navigationMobile-menuButton:after {
  -webkit-animation: openMenuBottom;
  -moz-animation: openMenuBottom;
  animation: openMenuBottom;
  -webkit-animation-duration: 0.6s;
  -moz-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.navigationMobile-navbar {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-box-pack: justify;
  justify-content: space-between;
  position: relative;
  margin-top: 32px;
  height: 20px;
}

.navigationMobile-navbar .iconFarcoLogo {
  width: 72px;
}

.navigationMobile-navbar .iconFarcoLogo path {
  fill: #505f43;
  -webkit-transition: fill 0.3s ease;
  -moz-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navigationMobile-menu {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: -webkit-calc(var(--vh, 1vh) * 100);
  height: -moz-calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  pointer-events: none;
}

.navigationMobile-menuWrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.navigationMobile-menuButton {
  display: block;
  position: absolute;
  right: 32px;
  bottom: 32px;
  width: 64px;
  height: 64px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-box-shadow: 4px 4px 32px rgba(0, 0, 0, 0.16);
  box-shadow: 4px 4px 32px rgba(0, 0, 0, 0.16);
  pointer-events: all;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.navigationMobile-menuButton:after,
.navigationMobile-menuButton:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 2px;
  background-color: #505f43;
}

.navigationMobile-menuButton:before {
  -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% - 3px))
    rotate(0deg);
  -moz-transform: translateX(-50%) translateY(-moz-calc(-50% - 3px))
    rotate(0deg);
  transform: translateX(-50%) translateY(calc(-50% - 3px)) rotate(0deg);
  -webkit-animation: closeMenuTop;
  -moz-animation: closeMenuTop;
  animation: closeMenuTop;
  -webkit-animation-duration: 0.6s;
  -moz-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
}

.navigationMobile-menuButton:after {
  -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% + 3px))
    rotate(0deg);
  -moz-transform: translateX(-50%) translateY(-moz-calc(-50% + 3px))
    rotate(0deg);
  transform: translateX(-50%) translateY(calc(-50% + 3px)) rotate(0deg);
  -webkit-animation: closeMenuBottom;
  -moz-animation: closeMenuBottom;
  animation: closeMenuBottom;
  -webkit-animation-duration: 0.6s;
  -moz-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
}

.mainLayout.is-dark .navigationMobile .iconFarcoLogo path,
.navigationMobile.is-contact .iconFarcoLogo path {
  fill: #fff;
}

.mainLayout.is-dark .navigationMobile .link-text,
.navigationMobile.is-contact .link-text {
  color: #fff;
}

@-webkit-keyframes openMenuTop {
  0% {
    background-color: #505f43;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% - 3px))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% - 3px)) rotate(0deg);
  }

  50% {
    background-color: #fff;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% - 0))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% - 0px)) rotate(0deg);
  }

  to {
    background-color: #fff;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% - 0))
      rotate(-45deg);
    transform: translateX(-50%) translateY(calc(-50% - 0px)) rotate(-45deg);
  }
}

@-moz-keyframes openMenuTop {
  0% {
    background-color: #505f43;
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% - 3px))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% - 3px)) rotate(0deg);
  }

  50% {
    background-color: #fff;
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% - 0))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% - 0px)) rotate(0deg);
  }

  to {
    background-color: #fff;
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% - 0))
      rotate(-45deg);
    transform: translateX(-50%) translateY(calc(-50% - 0px)) rotate(-45deg);
  }
}

@keyframes openMenuTop {
  0% {
    background-color: #505f43;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% - 3px))
      rotate(0deg);
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% - 3px))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% - 3px)) rotate(0deg);
  }

  50% {
    background-color: #fff;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% - 0))
      rotate(0deg);
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% - 0))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% - 0px)) rotate(0deg);
  }

  to {
    background-color: #fff;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% - 0))
      rotate(-45deg);
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% - 0))
      rotate(-45deg);
    transform: translateX(-50%) translateY(calc(-50% - 0px)) rotate(-45deg);
  }
}

@-webkit-keyframes closeMenuTop {
  0% {
    background-color: #fff;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% - 0))
      rotate(-45deg);
    transform: translateX(-50%) translateY(calc(-50% - 0px)) rotate(-45deg);
  }

  50% {
    background-color: #fff;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% - 0))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% - 0px)) rotate(0deg);
  }

  to {
    background-color: #505f43;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% - 3px))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% - 3px)) rotate(0deg);
  }
}

@-moz-keyframes closeMenuTop {
  0% {
    background-color: #fff;
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% - 0))
      rotate(-45deg);
    transform: translateX(-50%) translateY(calc(-50% - 0px)) rotate(-45deg);
  }

  50% {
    background-color: #fff;
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% - 0))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% - 0px)) rotate(0deg);
  }

  to {
    background-color: #505f43;
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% - 3px))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% - 3px)) rotate(0deg);
  }
}

@keyframes closeMenuTop {
  0% {
    background-color: #fff;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% - 0))
      rotate(-45deg);
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% - 0))
      rotate(-45deg);
    transform: translateX(-50%) translateY(calc(-50% - 0px)) rotate(-45deg);
  }

  50% {
    background-color: #fff;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% - 0))
      rotate(0deg);
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% - 0))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% - 0px)) rotate(0deg);
  }

  to {
    background-color: #505f43;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% - 3px))
      rotate(0deg);
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% - 3px))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% - 3px)) rotate(0deg);
  }
}

@-webkit-keyframes openMenuBottom {
  0% {
    background-color: #505f43;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% + 3px))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% + 3px)) rotate(0deg);
  }

  50% {
    background-color: #fff;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% + 0))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% + 0px)) rotate(0deg);
  }

  to {
    background-color: #fff;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% + 0))
      rotate(45deg);
    transform: translateX(-50%) translateY(calc(-50% + 0px)) rotate(45deg);
  }
}

@-moz-keyframes openMenuBottom {
  0% {
    background-color: #505f43;
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% + 3px))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% + 3px)) rotate(0deg);
  }

  50% {
    background-color: #fff;
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% + 0))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% + 0px)) rotate(0deg);
  }

  to {
    background-color: #fff;
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% + 0))
      rotate(45deg);
    transform: translateX(-50%) translateY(calc(-50% + 0px)) rotate(45deg);
  }
}

@keyframes openMenuBottom {
  0% {
    background-color: #505f43;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% + 3px))
      rotate(0deg);
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% + 3px))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% + 3px)) rotate(0deg);
  }

  50% {
    background-color: #fff;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% + 0))
      rotate(0deg);
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% + 0))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% + 0px)) rotate(0deg);
  }

  to {
    background-color: #fff;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% + 0))
      rotate(45deg);
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% + 0))
      rotate(45deg);
    transform: translateX(-50%) translateY(calc(-50% + 0px)) rotate(45deg);
  }
}

@-webkit-keyframes closeMenuBottom {
  0% {
    background-color: #fff;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% + 0))
      rotate(45deg);
    transform: translateX(-50%) translateY(calc(-50% + 0px)) rotate(45deg);
  }

  50% {
    background-color: #fff;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% + 0))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% + 0px)) rotate(0deg);
  }

  to {
    background-color: #505f43;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% + 3px))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% + 3px)) rotate(0deg);
  }
}

@-moz-keyframes closeMenuBottom {
  0% {
    background-color: #fff;
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% + 0))
      rotate(45deg);
    transform: translateX(-50%) translateY(calc(-50% + 0px)) rotate(45deg);
  }

  50% {
    background-color: #fff;
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% + 0))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% + 0px)) rotate(0deg);
  }

  to {
    background-color: #505f43;
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% + 3px))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% + 3px)) rotate(0deg);
  }
}

@keyframes closeMenuBottom {
  0% {
    background-color: #fff;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% + 0))
      rotate(45deg);
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% + 0))
      rotate(45deg);
    transform: translateX(-50%) translateY(calc(-50% + 0px)) rotate(45deg);
  }

  50% {
    background-color: #fff;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% + 0))
      rotate(0deg);
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% + 0))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% + 0px)) rotate(0deg);
  }

  to {
    background-color: #505f43;
    -webkit-transform: translateX(-50%) translateY(-webkit-calc(-50% + 3px))
      rotate(0deg);
    -moz-transform: translateX(-50%) translateY(-moz-calc(-50% + 3px))
      rotate(0deg);
    transform: translateX(-50%) translateY(calc(-50% + 3px)) rotate(0deg);
  }
}

.persperctiveImage {
  display: none;
  position: absolute;
  top: -webkit-calc(50% - 130px);
  top: -moz-calc(50% - 130px);
  top: calc(50% - 130px);
  left: -webkit-calc(50% - 130px);
  left: -moz-calc(50% - 130px);
  left: calc(50% - 130px);
  width: 260px;
  height: 260px;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.persperctiveImage .picture,
.persperctiveImage .picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.persperctiveImage .picture {
  -webkit-transform: perspective(1800px) rotateY(-18deg);
  -moz-transform: perspective(1800px) rotateY(-18deg);
  transform: perspective(1800px) rotateY(-18deg);
  -webkit-transform-origin: right;
  -moz-transform-origin: right;
  transform-origin: right;
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  -moz-transition: transform 1s, -moz-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s, -moz-transform 1s;
}

/* @media (min-width: 768px) and (hover: hover) {
  .persperctiveImage {
    display: block;
  }
} */

@media (min-width: 1440px) {
  .persperctiveImage {
    width: 320px;
    height: 320px;
  }
}

.persperctiveImageMobile {
  display: none;
  position: absolute;
  right: 24px;
  bottom: 36px;
  width: 34.483vh;
  height: 34.483vh;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(16px);
  -moz-transform: translateY(16px);
  transform: translateY(16px);
  -webkit-transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, -webkit-transform 1s;
  -moz-transition: opacity 1s, transform 1s, -moz-transform 1s;
  transition: opacity 1s, transform 1s;
  transition: opacity 1s, transform 1s, -webkit-transform 1s, -moz-transform 1s;
}

.persperctiveImageMobile.is-active {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
}

.persperctiveImageMobile.is-active .picture {
  -webkit-transform: perspective(1800px) rotateY(0deg);
  -moz-transform: perspective(1800px) rotateY(0deg);
  transform: perspective(1800px) rotateY(0deg);
  -webkit-transition: -webkit-transform 4s;
  transition: -webkit-transform 4s;
  -moz-transition: transform 4s, -moz-transform 4s;
  transition: transform 4s;
  transition: transform 4s, -webkit-transform 4s, -moz-transform 4s;
}

.persperctiveImageMobile .picture,
.persperctiveImageMobile .picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.persperctiveImageMobile .picture {
  -webkit-transform: perspective(1800px) rotateY(-24deg);
  -moz-transform: perspective(1800px) rotateY(-24deg);
  transform: perspective(1800px) rotateY(-24deg);
  -webkit-transform-origin: right;
  -moz-transform-origin: right;
  transform-origin: right;
  -webkit-transition: -webkit-transform 3s 1s;
  transition: -webkit-transform 3s 1s;
  -moz-transition: transform 3s 1s, -moz-transform 3s 1s;
  transition: transform 3s 1s;
  transition: transform 3s 1s, -webkit-transform 3s 1s, -moz-transform 3s 1s;
}

@media (min-width: 375px) {
  .persperctiveImageMobile {
    display: block;
  }
}

.picture,
.program {
  display: block;
  position: relative;
}

.program {
  margin-top: 80px;
}

.program + .program {
  margin-top: 48px;
}

.program .mediaBlock {
  margin-left: -24px;
  width: -webkit-calc(100% + 48px);
  width: -moz-calc(100% + 48px);
  width: calc(100% + 48px);
}

.program .description {
  margin-top: 48px;
}

@media (min-width: 768px) {
  .program {
    margin-top: 144px;
  }

  .program + .program {
    margin-top: 88px;
  }

  .program .mediaBlock {
    margin-left: 0;
    width: 100%;
  }

  .program .description {
    margin-top: 80px;
  }
}

.relatedLink {
  display: block;
  position: relative;
  color: #333;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.relatedLink.is-reverse .relatedLink-wrapper {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: reverse;
  flex-direction: column-reverse;
}

.relatedLink.is-reverse .relatedLink-block + .relatedLink-block {
  margin-top: 0;
  margin-bottom: 8px;
}

.relatedLink.is-sneakPeak .relatedLink-block:first-child {
  display: none;
}

.relatedLink.is-sneakPeak .relatedLink-block:nth-child(2) {
  margin-top: 0;
}

/* @media (hover: hover) {
  .relatedLink.is-wrapper:hover .relatedLink-titleText {
    color: #94a479;
  }
} */

.relatedLink-wrapper {
  display: block;
  position: relative;
  padding-bottom: 16px;
  min-width: 100%;
  -webkit-transform: perspective(400px) rotateX(0deg);
  -moz-transform: perspective(400px) rotateX(0deg);
  transform: perspective(400px) rotateX(0deg);
  -webkit-transform-origin: bottom;
  -moz-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.relatedLink-wrapper:before {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #e1e1e1;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.relatedLink-block {
  display: block;
  position: relative;
}

.relatedLink-block + .relatedLink-block {
  margin-top: 8px;
}

.relatedLink-category {
  display: block;
  position: relative;
  text-transform: none;
}

.relatedLink-logo {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: start;
  justify-content: flex-start;
  position: relative;
  width: 112px;
  height: 48px;
}

.relatedLink-logo .icon,
.relatedLink-logo .picture,
.relatedLink-logo .picture img {
  width: auto;
  max-width: 112px;
  height: auto;
  max-height: 48px;
}

.relatedLink-title {
  display: block;
  position: relative;
  text-transform: uppercase;
}

.relatedLink-titleText {
  display: block;
  position: relative;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.relatedLink-titleText:hover {
  z-index: 1;
}

/* @media (hover: hover) {
  .relatedLink-titleText:hover .persperctiveImage {
    opacity: 1;
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    transition: opacity 1s;
  }

  .relatedLink-titleText:hover .persperctiveImage .picture {
    -webkit-transform: perspective(1800px) rotateY(0deg);
    -moz-transform: perspective(1800px) rotateY(0deg);
    transform: perspective(1800px) rotateY(0deg);
  }
} */

.relatedLink-titleText .persperctiveImage {
  z-index: -1;
}

.relatedLink-titleCaption {
  display: block;
  position: relative;
  margin-top: 8px;
  color: #333;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.relatedLink-titleCaption > span {
  text-transform: none;
}

.relatedLink-titleCaption > span:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 0 16px;
  width: 24px;
  height: 1px;
  background-color: #c4c4c4;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.relatedLink-wrapperLink,
.relatedLink-wrapperLink .link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.relatedLink-wrapperLink .link {
  cursor: none;
}

.relatedLink .link-text {
  white-space: nowrap;
}

@media (min-width: 1024px) {
  .relatedLink-wrapper {
    -moz-box-align: center;
    align-items: center;
    flex-wrap: nowrap;
    -moz-box-pack: justify;
    justify-content: space-between;
    padding-bottom: 24px;
  }

  .relatedLink-wrapper,
  .relatedLink.is-reverse .relatedLink-wrapper {
    display: -moz-box;
    display: flex;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
  }

  .relatedLink.is-reverse .relatedLink-block + .relatedLink-block {
    margin-bottom: 0;
  }

  .relatedLink.is-sneakPeak .relatedLink-block:first-child {
    display: block;
  }

  .relatedLink-block + .relatedLink-block {
    margin-top: 0;
  }

  .relatedLink-title {
    display: -moz-inline-box;
    display: inline-flex;
    -moz-box-align: start;
    align-items: flex-start;
    -moz-box-orient: horizontal;
    -moz-box-direction: reverse;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    -moz-box-pack: end;
    justify-content: flex-end;
    text-align: right;
  }

  .relatedLink-titleCaption {
    margin-top: 10px;
    margin-right: 16px;
  }
}

.mainLayout.is-dark .relatedLink {
  color: #fff;
}

.mainLayout.is-dark .relatedLink-titleCaption {
  color: #999;
}

.mainLayout.is-dark .relatedLink-wrapper:before {
  background-color: #333;
}

.relatedLinkContainer {
  display: block;
  position: relative;
}

.relatedLinkContainer + * {
  margin-top: 80px;
}

.relatedLinkContainer .relatedLink + .relatedLink .relatedLink-wrapper {
  padding-top: 24px;
}

.relatedLinkContainer .relatedLink:last-child {
  padding-bottom: 0;
}

.relatedLinkContainer .relatedLink:last-child:before {
  display: none;
}

@media (min-width: 768px) {
  .relatedLinkContainer + * {
    margin-top: 144px;
  }
}

.sectionTitle-description,
.sectionTitle-title {
  text-align: left;
}

.sectionTitle-title {
  margin: 0 auto;
}

.sectionTitle-description,
.sectionTitle-title {
  display: block;
  position: relative;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.sectionTitle-description {
  margin: 16px auto 0;
}

@media (min-width: 768px) {
  .sectionTitle-description,
  .sectionTitle-title {
    padding: 0 8.33%;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .sectionTitle-description {
    margin-top: 56px;
  }
}

@media (min-width: 1280px) {
  .sectionTitle-description {
    padding: 0 16.66%;
  }
}

@media (min-width: 1440px) {
  .sectionTitle-description {
    padding: 0 20%;
  }
}

.servicesDistributor {
  color: #fff;
  padding-top: 144px;
}

.servicesDistributor .servicesDistributor-title {
  margin-bottom: 48px;
}

@media (min-width: 768px) {
  .servicesDistributor .servicesDistributor-title {
    margin-bottom: 64px;
  }
}

.servicesDistributor .item-title {
  display: -moz-box;
  display: flex;
}

@media (min-width: 1280px) {
  .servicesDistributor .item-title {
    width: -webkit-calc(50% - 64px);
    width: -moz-calc(50% - 64px);
    width: calc(50% - 64px);
  }
}

.servicesDistributor .item-title span {
  margin-top: 3px;
  margin-right: 22px;
}

@media (min-width: 768px) {
  .servicesDistributor .item-title span {
    margin-top: 14px;
    margin-right: 40px;
  }
}

.servicesDistributor .list-title {
  color: #fff;
  margin-bottom: 16px;
}

@media (min-width: 1280px) {
  .servicesDistributor .list-title {
    margin-bottom: 24px;
  }
}

.servicesDistributor .ul-container {
  margin: 32px 32px 0 46px;
  color: #999;
}

@media (min-width: 1280px) {
  .servicesDistributor .ul-container {
    width: -webkit-calc(50% - 64px);
    width: -moz-calc(50% - 64px);
    width: calc(50% - 64px);
  }
}

.servicesDistributor .ul-container > div:first-child {
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .servicesDistributor .ul-container > div:first-child {
    margin-bottom: 0;
  }
}

@media (min-width: 667px) {
  .servicesDistributor .ul-container {
    display: -moz-box;
    display: flex;
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
    -moz-column-gap: 32px;
    column-gap: 32px;
  }

  .servicesDistributor .ul-container > div {
    -moz-box-flex: 1;
    flex-grow: 1;
    width: 50%;
  }
}

@media (min-width: 768px) {
  .servicesDistributor .ul-container {
    margin: 32px 32px 0 64px;
  }
}

@media (min-width: 1280px) {
  .servicesDistributor .ul-container {
    margin-top: 16px;
  }
}

.servicesDistributor li {
  list-style: none;
  margin-bottom: 16px;
}

.servicesDistributor li:last-child {
  margin-bottom: 0;
}

@media (min-width: 1280px) {
  .servicesDistributor li {
    margin-bottom: 24px;
  }
}

.servicesDistributor .service-item {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  padding: 32px 0 48px;
  position: relative;
  border-top: 1px solid hsla(0, 0%, 100%, 0.2);
  -moz-box-pack: justify;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .servicesDistributor .service-item {
    padding: 32px 0 68px;
  }
}

@media (min-width: 1280px) {
  .servicesDistributor .service-item {
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
    padding: 40px 0 96px;
  }
}

.servicesDistributor .service-item:last-child {
  padding-bottom: 0;
}

.testimony {
  color: #333;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.testimony,
.testimony-text {
  display: block;
  position: relative;
}

.testimony-text {
  z-index: 1;
}

.testimony-text p,
.testimony-text span {
  display: block;
  position: relative;
}

/* @media (hover: hover) {
  .testimony-text p:hover .persperctiveImage {
    opacity: 1;
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    transition: opacity 1s;
  }

  .testimony-text p:hover .persperctiveImage .picture {
    -webkit-transform: perspective(1800px) rotateY(0deg);
    -moz-transform: perspective(1800px) rotateY(0deg);
    transform: perspective(1800px) rotateY(0deg);
  }
} */

.testimony-info {
  margin-top: 24px;
  text-align: right;
}

.testimony-info,
.testimony-info-text {
  display: block;
  position: relative;
}

.testimony-info-text + .testimony-info-text {
  margin-top: 8px;
}

@media (min-width: 768px) {
  .testimony-info {
    margin-top: 32px;
  }

  .testimony-info-text {
    display: inline-block;
  }

  .testimony-info-text + .testimony-info-text {
    margin-top: 0;
    margin-left: 4px;
  }
}

@media (min-width: 1280px) {
  .testimony-info,
  .testimony-text {
    padding-left: 8.33%;
  }
}

@media (min-width: 1680px) {
  .testimony-info,
  .testimony-text {
    padding-right: 8.33%;
    padding-left: 8.33%;
  }
}

.mainLayout.is-dark .testimony {
  color: #fff;
}

.reel {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.reel.is-hidden:after {
  background-color: rgba(0, 0, 0, 0.5);
}

.reel-wrapper {
  display: block;
  position: relative;
  left: 50%;
  width: auto;
  height: 82vw;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

.reel .video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.reel:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  -webkit-transition: background-color 0.6s;
  -moz-transition: background-color 0.6s;
  transition: background-color 0.6s;
}

@media (min-width: 768px) {
  .reel-wrapper {
    width: 100%;
    height: auto;
  }
}

.video {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}

.video-featured__content {
  margin-top: 32px;
}

@media (min-width: 1024px) {
  .video-featured__content {
    min-width: 66.66%;
    max-width: 66.66%;
  }
}

.video-featured img,
.video-featured video {
  pointer-events: none;
}

.video-featured .video {
  height: 100%;
  object-fit: cover;
}

.video-featured .picture-container {
  overflow: hidden;
  height: 60vh;
}

@media (min-width: 768px) {
  .video-featured .picture-container {
    height: 100%;
    margin-left: -30px;
    margin-right: -30px;
  }
}

@media (min-width: 1024px) {
  .video-featured .picture-container {
    margin-left: -80px;
    margin-right: -80px;
  }
}

.video-featured .picture-container__inner {
  height: 100%;
}

.video-featured .picture {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transform-origin: top center;
  -moz-transform-origin: top center;
  transform-origin: top center;
}

.video-featured .picture,
.video-featured .picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-featured-category {
  color: #999;
  margin-top: 32px;
  margin-bottom: 8px;
}

.video-featured .link {
  margin-top: 16px;
  height: 100%;
}

@media (min-width: 667px) {
  .video-featured .link {
    margin-top: 32px;
  }
}

.video-featured-text {
  display: block;
  position: relative;
  margin-top: 16px;
  text-align: left;
  color: #333;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.video-featured-text ul {
  list-style: disc;
  padding-left: 1em;
}

.video-featured-text h1 + *,
.video-featured-text h2 + *,
.video-featured-text h3 + *,
.video-featured-text h4 + *,
.video-featured-text h5 + *,
.video-featured-text h6 + *,
.video-featured-text p + * {
  margin-top: 32px;
}

.video-featured-text h1 + p,
.video-featured-text h2 + p,
.video-featured-text h3 + p,
.video-featured-text h4 + p,
.video-featured-text h5 + p,
.video-featured-text h6 + p {
  margin-top: 8px;
}

.video-featured-text a {
  color: #94a479;
}

.caseStudy {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.caseStudy,
.caseStudy-content,
.caseStudy-fullMedia,
.caseStudy-mainMedia {
  display: block;
  position: relative;
}

.caseStudy-fullMedia + *,
.caseStudy-mainMedia + * {
  margin-top: 80px;
}

.caseStudy-fullMedia .picture,
.caseStudy-fullMedia .picture img,
.caseStudy-mainMedia .picture,
.caseStudy-mainMedia .picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.caseStudy-mainMedia .mediaBlock {
  margin-top: 0 !important;
}

.caseStudy .sectionWrapper {
  text-align: left;
}

.caseStudy .sectionWrapper .mediaBlock {
  height: 61.87vw;
}

.caseStudy-subtitle {
  display: block;
  position: relative;
  margin: 80px 0 24px;
  color: #333;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.caseStudy-subtitle.is-centered {
  text-align: center;
}

.caseStudy .description + * {
  margin-top: 80px;
}

.caseStudy .description + * > .mediaBlock {
  margin-top: 0;
}

.caseStudy .mediaBlock {
  margin-top: 80px;
}

.caseStudy-team {
  display: block;
  position: relative;
}

.caseStudy-teamMember {
  display: -moz-box;
  display: flex;
  -moz-box-align: start;
  align-items: flex-start;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-box-pack: justify;
  justify-content: space-between;
  position: relative;
  color: #333;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.caseStudy-teamMember + .caseStudy-teamMember {
  margin-top: 8px;
}

.caseStudy-teamMember-name,
.caseStudy-teamMember-rol {
  display: block;
  position: relative;
}

.caseStudy-teamMember-rol {
  color: #999;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
  text-align: right;
}

.caseStudy-cyphers {
  display: block;
  position: relative;
  text-align: left;
}

.caseStudy-cyphers li {
  display: block;
  position: relative;
  padding-bottom: 24px;
  color: #333;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid #e1e1e1;
  -webkit-transition: color 0.3s ease, border 0.3s ease;
  -moz-transition: color 0.3s ease, border 0.3s ease;
  transition: color 0.3s ease, border 0.3s ease;
}

.caseStudy-cyphers li + li {
  margin-top: 24px;
}

.caseStudy-cyphers-cypher,
.caseStudy-cyphers-description {
  display: block;
  position: relative;
  margin: 0 auto;
}

.caseStudy .testimony {
  margin-top: 80px;
}

.caseStudy-conclusionClaim {
  display: block;
  position: relative;
  margin-bottom: 16px;
  color: #333;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.caseStudy-related,
.caseStudy-related .relatedLinkContainer {
  margin-top: 48px;
}

.caseStudy-nextCase {
  color: #333;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.caseStudy-nextCase,
.caseStudy-nextCase-title {
  display: block;
  position: relative;
}

.caseStudy-nextCase-titleContainer {
  display: inline-block;
  position: relative;
}

.caseStudy-nextCase-titleContainer .link {
  cursor: none;
}

/* @media (hover: hover) {
  .caseStudy-nextCase-titleContainer:hover {
    z-index: 1;
  }

  .caseStudy-nextCase-titleContainer:hover .persperctiveImage {
    opacity: 1;
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    transition: opacity 1s;
  }

  .caseStudy-nextCase-titleContainer:hover .persperctiveImage .picture {
    -webkit-transform: perspective(1800px) rotateY(0deg);
    -moz-transform: perspective(1800px) rotateY(0deg);
    transform: perspective(1800px) rotateY(0deg);
  }
} */

.caseStudy-nextCase-titleContainer .persperctiveImage {
  z-index: -1;
}

.caseStudy-nextCase-client,
.caseStudy-nextCase-description {
  display: block;
  position: relative;
}

.caseStudy-nextCase-client {
  margin-top: 16px;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.caseStudy-nextCase-client + .link {
  margin-top: 32px;
}

@media (max-width: 767px) {
  .caseStudy-cyphers-cypher {
    font-size: 64px;
    line-height: 96px;
  }
}

@media (min-width: 768px) {
  .caseStudy .sectionWrapper .mediaBlock {
    height: auto;
  }

  .caseStudy-fullMedia + *,
  .caseStudy-mainMedia + * {
    margin-top: 144px;
  }

  .caseStudy-fullMedia + * .picture,
  .caseStudy-fullMedia + * .picture img,
  .caseStudy-mainMedia + * .picture,
  .caseStudy-mainMedia + * .picture img {
    height: auto;
  }

  .caseStudy-subtitle {
    margin: 144px 0 88px;
  }

  .caseStudy .description + * {
    margin-top: 144px;
  }

  .caseStudy .description + * > .mediaBlock {
    margin-top: 0;
  }

  .caseStudy .mediaBlock {
    margin-top: 144px;
  }

  .caseStudy-teamMember + .caseStudy-teamMember {
    margin-top: 16px;
  }

  .caseStudy-cyphers-cypher,
  .caseStudy-cyphers-description {
    text-align: center;
  }

  .caseStudy .testimony {
    margin-top: 144px;
  }

  .caseStudy-conclusionClaim {
    margin-bottom: 88px;
  }

  .caseStudy-related,
  .caseStudy-related .relatedLinkContainer {
    margin-top: 144px;
  }

  .caseStudy-nextCase-description {
    margin-top: 64px;
    margin-left: 224px;
  }

  .caseStudy-nextCase-client {
    margin-top: 24px;
    margin-left: 224px;
  }

  .caseStudy-nextCase-client + .link {
    margin-top: 40px;
    margin-left: 224px;
  }
}

/* @media (min-width: 768px) and (hover: hover) {
  .caseStudy-nextCase-client + .link {
    display: none;
  }
} */

@media (min-width: 1024px) {
  .caseStudy-subtitle.is-centered {
    text-align: left;
  }

  .caseStudy-cyphers {
    display: -moz-box;
    display: flex;
    -moz-box-align: start;
    align-items: flex-start;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
    flex-wrap: nowrap;
    -moz-box-pack: start;
    justify-content: flex-start;
  }

  .caseStudy-cyphers li {
    padding-top: 32px;
    padding-bottom: 0;
    min-width: -webkit-calc(33.33% - 43px);
    min-width: -moz-calc(33.33% - 43px);
    min-width: calc(33.33% - 43px);
    max-width: -webkit-calc(33.33% - 43px);
    max-width: -moz-calc(33.33% - 43px);
    max-width: calc(33.33% - 43px);
    border-top: 1px solid #e1e1e1;
    border-bottom: 0;
  }

  .caseStudy-cyphers li + li {
    margin-top: 0;
    margin-left: 64px;
  }

  .caseStudy-cyphers-description {
    margin-top: 24px;
  }

  .caseStudy-nextCase-client,
  .caseStudy-nextCase-description {
    max-width: 50%;
  }
}

@media (min-width: 1440px) {
  .caseStudy-nextCase-client,
  .caseStudy-nextCase-description {
    max-width: 40%;
  }

  .caseStudy-conclusionClaim {
    padding-right: 8.33%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media (min-width: 1680px) {
  .caseStudy-conclusionClaim {
    padding-right: 16.66%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.mainLayout.is-dark .caseStudy-conclusionClaim,
.mainLayout.is-dark .caseStudy-nextCase,
.mainLayout.is-dark .caseStudy-subtitle,
.mainLayout.is-dark .caseStudy-teamMember,
.mainLayout.is-dark .caseStudy-teamMember-rol {
  color: #fff;
}

.mainLayout.is-dark .caseStudy-cyphers li {
  color: #fff;
  border-bottom: 1px solid #333;
}

.mainLayout.is-dark .caseStudy-nextCase-client {
  color: #999;
}

@media (min-width: 1024px) {
  .mainLayout.is-dark .caseStudy-cyphers li {
    border-top: 1px solid #333;
    border-bottom: 0;
  }
}

.caseStudyContainer {
  display: block;
  position: relative;
  margin-top: 80px;
}

.caseStudyContainer + .caseStudy-gallery {
  margin-top: 80px;
}

.caseStudyContainer-container {
  display: block;
  position: relative;
}

.caseStudyContainer .caseStudyCover:not(.is-full:first-child) {
  margin-top: 80px;
}

@media (min-width: 768px) {
  .caseStudyContainer,
  .caseStudyContainer + .caseStudy-gallery {
    margin-top: 144px;
  }

  .caseStudyContainer-container {
    display: -moz-box;
    display: flex;
    -moz-box-align: start;
    align-items: flex-start;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
    flex-wrap: wrap;
    -moz-box-pack: start;
    justify-content: flex-start;
    margin-left: -32px;
    width: -webkit-calc(100% + 64px);
    width: -moz-calc(100% + 64px);
    width: calc(100% + 64px);
  }

  .caseStudyContainer .caseStudyCover.is-full {
    margin: 0 32px;
    min-width: -webkit-calc(100% - 64px);
    min-width: -moz-calc(100% - 64px);
    min-width: calc(100% - 64px);
    max-width: -webkit-calc(100% - 64px);
    max-width: -moz-calc(100% - 64px);
    max-width: calc(100% - 64px);
  }

  .caseStudyContainer .caseStudyCover:not(.is-full) {
    margin: 0 32px;
    min-width: -webkit-calc(50% - 64px);
    min-width: -moz-calc(50% - 64px);
    min-width: calc(50% - 64px);
    max-width: -webkit-calc(50% - 64px);
    max-width: -moz-calc(50% - 64px);
    max-width: calc(50% - 64px);
  }

  .caseStudyContainer .caseStudyCover:not(.is-full:first-child) {
    margin-top: 144px;
  }
}

.caseStudyCover {
  display: block;
  position: relative;
}

.caseStudyCover .link {
  height: 100%;
}

.caseStudyCover-category {
  color: #999;
  margin-top: 32px;
  margin-bottom: 8px;
}

.caseStudyCover-fadeLayer {
  display: block;
  position: relative;
  width: auto;
  height: auto;
}

.caseStudyCover-mediaWrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 61.87vw;
  overflow: hidden;
}

.caseStudyCover .picture,
.caseStudyCover .picture img,
.caseStudyCover .video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.caseStudyCover.has-controls .video {
  pointer-events: all;
}

.caseStudyCover .picture {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transform-origin: top center;
  -moz-transform-origin: top center;
  transform-origin: top center;
}

.caseStudyCover-title {
  margin-top: 32px;
  color: #333;
}

.caseStudyCover-text,
.caseStudyCover-title {
  display: block;
  position: relative;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.caseStudyCover-text {
  margin-top: 16px;
  text-align: left;
  color: #999;
}

.caseStudyCover-text ul {
  list-style: disc;
  padding-left: 1em;
}

.caseStudyCover-text h1 + *,
.caseStudyCover-text h2 + *,
.caseStudyCover-text h3 + *,
.caseStudyCover-text h4 + *,
.caseStudyCover-text h5 + *,
.caseStudyCover-text h6 + *,
.caseStudyCover-text p + * {
  margin-top: 32px;
}

.caseStudyCover-text h1 + p,
.caseStudyCover-text h2 + p,
.caseStudyCover-text h3 + p,
.caseStudyCover-text h4 + p,
.caseStudyCover-text h5 + p,
.caseStudyCover-text h6 + p {
  margin-top: 8px;
}

.caseStudyCover-text a {
  color: #94a479;
}

.caseStudyCover-description {
  display: block;
  position: relative;
  margin-top: 8px;
  color: #333;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.caseStudyCover .link {
  margin-top: 16px;
}

@media (max-width: 767px) {
  .caseStudyCover.has-translate {
    -webkit-transform: none !important;
    -moz-transform: none !important;
    transform: none !important;
  }
}

@media (min-width: 768px) {
  .caseStudyCover.has-translate {
    -webkit-transform: translateY(15%);
    -moz-transform: translateY(15%);
    transform: translateY(15%);
  }

  .caseStudyCover.is-full .caseStudyCover-description {
    max-width: 768px;
  }

  .caseStudyCover-mediaWrapper {
    width: auto;
    height: auto;
  }

  .caseStudyCover-description {
    margin-top: 16px;
    max-width: 464px;
  }

  .caseStudyCover .link {
    margin-top: 32px;
  }
}

.mainLayout.is-dark .caseStudyCover-title {
  color: #fff;
}

.mainLayout.is-dark .caseStudyCover-description {
  color: #999;
}

.caseStudy-gallery {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.caseStudy-gallery + * {
  margin-top: 48px;
}

.caseStudy-gallery .sectionWrapper {
  margin-left: -24px;
  width: -webkit-calc(100% + 48px);
  width: -moz-calc(100% + 48px);
  width: calc(100% + 48px);
}

.caseStudy-galleryBlock {
  display: block;
  position: relative;
  width: 100%;
  height: 82vw;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
}

.caseStudy-galleryBlock + .caseStudy-galleryBlock {
  margin-top: 16px;
}

.caseStudy-galleryBlock .picture,
.caseStudy-galleryBlock .picture img,
.caseStudy-galleryBlock .video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .caseStudy-gallery + * {
    margin-top: 144px;
  }

  .caseStudy-gallery .sectionWrapper {
    display: -moz-box;
    display: flex;
    -moz-box-align: start;
    align-items: flex-start;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
    flex-wrap: wrap;
    -moz-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto -32px;
  }

  .caseStudy-galleryBlock {
    margin-bottom: 32px;
    min-width: -webkit-calc(50% - 16px);
    min-width: -moz-calc(50% - 16px);
    min-width: calc(50% - 16px);
    max-width: -webkit-calc(50% - 16px);
    max-width: -moz-calc(50% - 16px);
    max-width: calc(50% - 16px);
    height: auto;
  }

  .caseStudy-galleryBlock .picture,
  .caseStudy-galleryBlock .picture img,
  .caseStudy-galleryBlock .video {
    height: 100%;
  }

  .caseStudy-galleryBlock + .caseStudy-galleryBlock {
    margin-top: 0;
  }

  .caseStudy-galleryBlock.is-full {
    min-width: 100%;
    max-width: 100%;
  }
}

.caseStudy-header {
  display: block;
  position: relative;
  padding: 168px 0 80px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.caseStudy-header + * {
  margin-top: 80px;
}

.caseStudy-headerTitle {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;
  -moz-box-pack: start;
  justify-content: flex-start;
  position: relative;
  margin: 0 auto;
  color: #999;
  min-width: 50%;
  max-width: 780px;
}

.caseStudy-headerTitle + .caseStudy-headerSubtitle {
  margin-top: 8px;
}

.caseStudy-headerTitle span {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-box-pack: start;
  justify-content: flex-start;
  position: relative;
}

.caseStudy-headerTitle span + span:before {
  content: "";
  display: block;
  position: relative;
  margin: 0 16px;
  width: 56px;
  height: 1px;
  background-color: #c4c4c4;
}

.caseStudy-headerSubtitle {
  display: inline-block;
  position: relative;
  margin: 0 auto;
  color: #333;
  max-width: 780px;
}

@media (min-width: 768px) {
  .caseStudy-header {
    padding: 200px 0 144px;
  }

  .caseStudy-header + * {
    margin-top: 144px;
  }

  .caseStudy-headerTitle + .caseStudy-headerSubtitle {
    margin-top: 16px;
  }
}

@media (min-width: 1280px) {
  .caseStudy-headerSubtitle,
  .caseStudy-headerTitle {
    margin-left: 8.33%;
  }
}

.sneakPeak {
  display: block;
  position: relative;
  margin-top: 80px;
  color: #333;
}

.sneakPeak-subtitle,
.sneakPeak-title {
  display: block;
  position: relative;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.sneakPeak-subtitle {
  margin-top: 16px;
}

.sneakPeak .relatedLinkContainer {
  margin-top: 48px;
}

@media (min-width: 768px) {
  .sneakPeak {
    margin-top: 144px;
  }

  .sneakPeak-subtitle,
  .sneakPeak-title {
    text-align: center;
  }

  .sneakPeak-subtitle {
    margin-top: 32px;
  }

  .sneakPeak .relatedLinkContainer {
    margin-top: 120px;
  }
}

.mainLayout.is-dark .sneakPeak-subtitle,
.mainLayout.is-dark .sneakPeak-title {
  color: #fff;
}
